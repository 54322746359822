import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { BootstrapService } from '@core/bootstrap.service';
import { SfxService } from '@core/sfx.service';
import { AudioButton } from '@models/audio-button-style';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zbdr-superkids-audio-button',
  templateUrl: './superkids-audio-button.component.html',
  styleUrls: ['./superkids-audio-button.component.scss'],
})
export class SuperkidsAudioButtonComponent implements OnInit, OnDestroy {
  @Input() audioFile: string;
  @Input() buttonStyle: AudioButton = AudioButton.circle;
  @Input() tooltipText: string;
  @Input() autoPlay = false;
  @Input() queue = false;
  @Input() restartQueue = false;
  @Input() isAssessment: boolean = false;
  @Output() isPlaying: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() enableAudioPrompts: boolean = false;

  constructor(private sfx: SfxService, private bootstrap: BootstrapService) { }
  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.sfx.loadAudioMetadata(this.audioFile, this.getContentUrl());
    if (this.autoPlay) {
      this.play();
    }
  }

  getContentUrl(): string {
    return this.enableAudioPrompts ? `${this.bootstrap.mediaUrl}content/shared/audio-instruction/${this.audioFile}`
      : `${this.bootstrap.getSuperkidsAssetUrlByFile(this.audioFile)}`;
  }

  play() {
    if (this.queue) {
      this.subscriptions.push(this.sfx.playInQueue(this.sfx.sounds[this.audioFile])
        .subscribe((success) => {
          this.isPlaying.emit(success);
        }));
    } else {
      this.subscriptions.push(this.sfx.play(this.sfx.sounds[this.audioFile])
        .subscribe((success) => {
          this.isPlaying.emit(success);
        }));
    }
  }


  buttonClasses(): any {
    return this.enableAudioPrompts ? { btnSkTealCircle: this.buttonStyle === AudioButton.circle }
      : {
        btnSkAudioCircle: this.buttonStyle === AudioButton.circle,
        btnSkAudioRounded: this.buttonStyle === AudioButton.rounded,
      };
  }

  ngOnDestroy(): void {
    if (this.restartQueue) this.sfx.stopQueue();
  }
}
