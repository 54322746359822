import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'zbdrFormattedText',
})
export class FormattedTextPipe implements PipeTransform {
  // eslint-disable-next-line prefer-regex-literals
  private pattern: RegExp = new RegExp(`([^\\[\\]]+)\\[([a-z0-9-\\s]{0,})\\]|(.+)`, 'igm');

  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Formats text according to content sheet rules.
   *
   * Examples:
   *   - "ca[fgBlue]t" => '<span class="fgBlue">ca</span>t'.
   *   - "ca[]t[fgBlue]" => 'ca<span class="fgBlue">t</span>'.
   *   - "cat" => "cat".
   *   - "[][][]" => "[][][]"
   *   - "[something weird]" => "[something weird]"
   *   - "<span>blah</span>[] ca[fgBlue]t" => '<span>blah</span><span class="fgBlue"> ca</span>t'.
   */
  transform(value: string): string | SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.format(value));
  }

  /**
   * Creates a HTML string based on formatted text string.
   *
   * This returns raw HTML.
   */
  format(value: string): string {
    let formatted = '';
    let i = 0;
    let matches: RegExpExecArray;
    do {
      // Runs the pattern against the next substring of the value until there are no more matches or its run 100 times.
      // This is to prevent any runaway loops in case our regex is off.
      matches = this.pattern.exec(value);
      if (matches) {
        formatted = `${formatted}${this.getText(...matches)}`;
      }
      i++;
    } while (matches && i < 10000);
    return formatted;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getText(substring?: string, toFormat?: string, className?: string, ...args: any[]): string {
    if (toFormat && className) {
      return `<span class="${className}">${toFormat}</span>`;
    }
    if (toFormat) {
      return toFormat;
    }
    return substring;
  }
}
