import { Injectable } from '@angular/core';
import chance from 'chance';
import * as _ from 'lodash';
import randomatic from 'randomatic';

/**
 * Types of strings to generate
 */
export type RandomStringType = string;

/**
 * standardized types of strings
 */
export namespace randomStringTypes {
  /**
   * Lowercase alpha characters
   */
  export const lower: RandomStringType = 'a';
  /**
   * Uppercase alpha characters
   */
  export const upper: RandomStringType = 'A';
  /**
   * Numeric characters
   */
  export const numeric: RandomStringType = '0';
  /**
   * Special characters
   */
  export const special: RandomStringType = '!';
  /**
   * All characters (upper, lower, numeric, and special)
   */
  export const all: RandomStringType = '*';
  /**
   * Upper, lower, and numeric characters but no special characters
   */
  export const alphaNumeric: RandomStringType = 'aA0';
}

@Injectable()
export class RandomDataService {
  // eslint-disable-next-line new-cap
  _chance = new chance();
  private readonly alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ];

  /**
   * Generates a random string of specified types and length
   * @param type type of characters to generate, see randomStringTypes
   * @param length the length of the string to generate
   */
  randomString(type: RandomStringType = randomStringTypes.lower, length: number = 10) {
    return randomatic(type, length);
  }

  /**
   * Generates a random string of specified types and length and appends it to the supplied string
   * @param text text to append the random characters generated
   * @param type type of characters to generate, see randomStringTypes
   * @param length the length of the string to generate
   */
  appendRandomString(text: string, type: RandomStringType = randomStringTypes.lower, length: number = 10) {
    return `${text}${randomatic(type, length)}`;
  }

  appendRandomStringAndShuffle(text: string, type: RandomStringType = randomStringTypes.lower, length: number = 10): string[] {
    return _.shuffle(`${text}${randomatic(type, length)}`);
  }

  /** method will take the distinct values of the input text
   * and append additional random text values up to the length specified */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  appendRandomDistinctValuesAndShuffle(text: string, type: RandomStringType = randomStringTypes.lower, length: number = 10): string[] {
    let uniqueString = _.uniq(text);
    const randomLettersNeeded = _.max([length - uniqueString.length, 0]);
    const fillerOptions = _.difference(this.alphabet, uniqueString);
    const fillerStrings = this._chance.pickset(fillerOptions, randomLettersNeeded);
    uniqueString = _.concat(uniqueString, fillerStrings);
    uniqueString = this._chance.shuffle(uniqueString);

    return uniqueString;
  }

  /**
   * Gets a random integer between min and max.
   */
  randomInteger(min: number, max: number): number {
    return this._chance.integer({ min, max });
  }

  randomSet<T>(set: Array<T>, count?: number) {
    // Returns lower of count or set.length random.
    return this._chance.pickset(set, (count && set.length > count ? count : set.length));
  }
}

