import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { BootstrapService } from '@core/bootstrap.service';
import { timer } from 'rxjs';


/**
 * takes the element an element and reduces the font so the text fits into the space available
 */
@Directive({
  selector: '[zbdrMakeTextFit]'
})
export class MakeTextFitDirective implements AfterViewInit, OnChanges {
  /** use the input property to trigger the make fit in addition to AfterViewInit and the OnResize event */
  @Input() public zbdrMakeTextFit: any;

  private element: HTMLElement;

  constructor(el: ElementRef, private bootstrap: BootstrapService) {
    this.element = el.nativeElement as HTMLElement;
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: UIEvent) {
    this.makeFit();
  }

  ngOnChanges(changes) {
    if (changes && changes.zbdrMakeTextFit && changes.zbdrMakeTextFit.currentValue.length > 10) {
      timer(100).subscribe(() => {
        this.makeFit();
      });
    }
  }

  ngAfterViewInit(): void {
    this.element.style.whiteSpace = 'nowrap';
    this.makeFit();
  }

  private makeFit() {
    this.element.style.fontSize = null;

    if (this.element.clientWidth <= this.element.scrollWidth) {
      while (this.element.clientWidth < this.element.scrollWidth) {
        if (this.bootstrap.activity === 'spelling_completion') {
          const fs = +window.getComputedStyle(this.element).fontSize.replace('px', '') - 5;
          this.element.style.fontSize = `${fs}px`;
        } else {
          const fs = +window.getComputedStyle(this.element).fontSize.replace('px', '') - 1;
          this.element.style.fontSize = `${fs}px`;
        }
      }
    }
  }
}
