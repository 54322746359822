import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EditorModule } from '@tinymce/tinymce-angular';
import { BlockedDirective } from '../blocked.directive';
import { ComponentResolverService } from '../component-resolver.service';
import { DisableFormControlDirective } from '../disableFormControlDirective';
import { DndA11yKeyboardComponent } from '../dnd-a11y-keyboard/dnd-a11y-keyboard.component';
import { DndA11yStateButtonComponent } from '../dnd-a11y-state-button/dnd-a11y-state-button.component';
import { DrawingComponent } from '../drawing/drawing.component';
import { DroppableDirective } from '../droppable.directive';
import { FormattedTextPipe } from '../formatted-text.pipe';
import { InstructionsPanelComponent } from '../instructions-panel/instructions-panel.component';
import { KeyComponent } from '../key/key.component';
import { LoadingPlaceholderComponent } from '../loading-placeholder/loading-placeholder.component';
import { MakeTextFitDirective } from '../make-text-fit.directive';
import { MarkdownService } from '../markdown.service';
import { ProgressbarComponent } from '../progressbar/progressbar.component';
import { RewriteInputComponent } from '../rewrite-input/rewrite-input.component';
import { SkInstructionsPanelComponent } from '../sk-instructions-panel/sk-instructions-panel.component';
import { SuperkidsAudioButtonComponent } from '../superkids-audio-button/superkids-audio-button.component';
import { WistiaContainerComponent } from '../wistia-container/wistia-container.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    EditorModule,
    FormsModule,
  ],
  declarations: [
    BlockedDirective,
    DisableFormControlDirective,
    FormattedTextPipe,
    KeyComponent,
    LoadingPlaceholderComponent,
    InstructionsPanelComponent,
    DndA11yKeyboardComponent,
    DndA11yStateButtonComponent,
    DrawingComponent,
    SkInstructionsPanelComponent,
    WistiaContainerComponent,
    MakeTextFitDirective,
    DroppableDirective,
    ProgressbarComponent,
    SuperkidsAudioButtonComponent,
    RewriteInputComponent,
  ],
  exports: [
    BlockedDirective,
    MakeTextFitDirective,
    FormattedTextPipe,
    KeyComponent,
    LoadingPlaceholderComponent,
    InstructionsPanelComponent,
    DndA11yKeyboardComponent,
    DndA11yStateButtonComponent,
    DrawingComponent,
    SkInstructionsPanelComponent,
    WistiaContainerComponent,
    DisableFormControlDirective,
    DroppableDirective,
    ProgressbarComponent,
    SuperkidsAudioButtonComponent,
    RewriteInputComponent,
  ],
  providers: [
    ComponentResolverService,
    MarkdownService,
  ],
})
export class SharedModule { }
