<aside #dialog id="{{ id }}"
    class="dndA11yKeyboard m-0 p-0"
    role="dialog"
    aria-labelledby="dndA11yKeyboardHeader"
    tabindex="-1"
    aria-hidden="true"
    aria-modal="true"
    [ngClass]="{'dndA11yKeyboardVisible': enabled}">
  <h2 id="{{ id }}Header" class="dndA11yKeyboardHeader">{{title}}</h2>
  <ul id="{{ id }}Menu" role="menu" class="py-2 px-3 dndA11yKeyboardMenu d-flex flex-wrap" [class.justify-content-center]="isOneItem">
    <ng-container *ngFor="let item of items; index as i;">
      <li
        id="{{ id }}Selection{{i}}"
        class="dndA11yKeyboardSelection dndA11yKeyboardSelection{{ item.key }}"
        [class.dndA11yKeyboardSelectionActive]="selected === i"
        (focusin)="setActive(i)"
        (blur)="setActive(-1)">
        <button id="{{ id }}Selection{{ i }}Button" class="dndA11yKeyboardButton border-0 w-100" tabindex="0" [innerHTML]="getContent(item)" [attr.aria-label]="getLabel(item, i)" (click)="handleSelection(item)"></button>
      </li>
    </ng-container>
  </ul>
  <button id="{{ id }}CloseButton" class="btn dndA11yKeyboardCloseButton" (click)="close()" aria-label="Close">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
</aside>
