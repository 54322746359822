import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

@Injectable()
export class ComponentResolverService {
  private vcr: ViewContainerRef;
  private _components: {[key: string]: any} = {};

  constructor(private resolver: ComponentFactoryResolver) { }

  set containerRef(containerRef: ViewContainerRef) {
    this.vcr = containerRef;
  }

  set components(types: { [key: string]: any }) {
    this._components = types;
  }

  create(type: string): ComponentRef<any> {
    if (this._components[type]) {
      const factory = this.resolver.resolveComponentFactory(this._components[type]);
      return this.vcr.createComponent(factory);
    }

    throw Error('Component not found');
  }
}
