import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[zbdrDroppable]',
})
export class DroppableDirective {
  element: HTMLElement;

  @Input() dropEnabled = true;

  constructor(elRef: ElementRef) {
    this.element = elRef.nativeElement;

    this.element.ondragover = (event: DragEvent) => {
      if (this.dropEnabled) {
        event.preventDefault();
      }
    };
  }
}
