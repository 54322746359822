<canvas
  #canvas
  id="{{id}}"
  class="pageCanvas"
  [class.canvasFullSize]="zoomLevel === 100"
  [class.active]="isActive()"
  (mouseover)="mouseover($event)"
  (mousedown)="mousedown($event)"
  (mousemove)="mousemove($event)"
  (mouseup)="mouseup($event)"
  (mouseleave)="mouseleave($event)"
  (touchstart)="touchdown($event)"
  (touchmove)="touchmove($event)"
  (touchend)="touchend($event)"
  touch-action="none"></canvas>
