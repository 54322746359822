export enum DrawingTools {
  paint = 'paint',
  draw = 'draw',
  erase = 'erase',
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
  none = '',
}

export enum DrawingColors {
  black = '#000000',
  gray = '#8b8a8a',
  red = '#ff0303',
  green = '#00ff00',
}

export class DrawingPage {
  clickX: number[] = [];
  clickY: number[] = [];
  clickDrag = [];
  clickColor: DrawingColors[] = [];
  clickErase: boolean[] = [];
}

export class DrawingVectors {
  InitWidth: number;
  InitHeight: number;
  ScaleW: number;
  ScaleH: number;
  Zoom: number;
}
