/* eslint-disable no-param-reassign */
/* globals Remarkable */
import { ContentToken, ParagraphToken, Token } from 'remarkable';

/**
 * ZB Align Right plugin.
 *
 * This is not-compatible with CommonMark/Markdown indented code blocks.
 */
export const alignRightPlugin: Remarkable.Plugin = (md: Remarkable, options?: any): void => {
  const isAlignedRight = (state: Remarkable.StateBlock, line: number) => {
    const { src } = state;
    return state.tShift[line] > 4 && src.charCodeAt(state.eMarks[line]) !== 0x20;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const parser = (state: Remarkable.StateBlock, startLine: number, endLine: number, silent: boolean): boolean => {
    let currLine = startLine;
    let last = currLine;

    // Ignore tokens that are parsed ahead of this one that use indentation such as lists.
    const tokensFound = state.tokens.filter((t: Token) => (t.level > 0
      && t.lines
      && t.lines.length > 0
      && t.lines[0] === startLine));
    if (!isAlignedRight(state, startLine) || tokensFound.length > 0) {
      // Do not match when the indent is less than 4 or the line end character is a space.
      return false;
    }

    while (currLine <= endLine) {
      if (state.isEmpty(currLine) || !isAlignedRight(state, currLine)) {
        last = currLine;
        break;
      }
      currLine++;
    }

    state.line = currLine;
    state.tokens.push({
      type: 'align_right_open',
      tight: false,
      lines: [startLine, last],
      level: state.level,
    } as ParagraphToken);
    state.tokens.push({
      type: 'inline',
      content: state.getLines(startLine, last, state.blkIndent, false),
      level: state.level + 1,
      lines: [startLine, last],
      children: [],
    } as ContentToken);
    state.tokens.push({
      type: 'align_right_close',
      tight: false,
      level: state.level,
    } as ParagraphToken);

    return true;
  };

  md.block.ruler.before('list', 'align_right', parser, options);
  md.block.ruler.disable('code');

  md.renderer.rules.align_right_open = (): string => '<p class="text-end">';
  md.renderer.rules.align_right_close = (): string => '</p>';
};
