import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { BrowserService } from '@core/browser.service';

import { UIHelpers } from '../uihelpers';

@Component({
  selector: 'zbdr-key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss']
})
export class KeyComponent implements AfterViewInit {
  @Input()
    letter: string;

  /** alternative text to the letter input */
  @Input()
    altText: string;

  /** screen reader only text */
  @Input()
    srOnlyText: string;

  /** specify a number to multiply the width proportial
   * to height (1 creates a square key, 1.1+ creates rectangle keys)
   */
  @Input()
    widthMultiplier = 1;

  @ViewChild('key', { static: true })
    key: ElementRef;

  @Output()
    keyPressed = new EventEmitter<string>();

  private _shiftOn = false;
  private _capsLockOn = false;
  private _capsInitialCheck = false;

  get shiftOn(): boolean {
    if (this.browserService.isMac) {
      return this._shiftOn || this._capsLockOn;
    }
    return ((this._shiftOn && !this._capsLockOn) || (!this._shiftOn && this._capsLockOn));
  }

  constructor(private elementRef: ElementRef, private browserService: BrowserService, private renderer: Renderer2) { }

  ngAfterViewInit() {
    // jasmine doesn't wrap the compoent in the <zbdr-key> element so setting the ID will mess up the test runner
    if (this.elementRef.nativeElement.tagName.toLowerCase() === 'zbdr-key') {
      this.elementRef.nativeElement.id = `${this.keyId}Container`;
    }
    this.squareUpKey();
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: UIEvent) {
    this.squareUpKey();
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (this.isShiftKey(event)) {
      this._shiftOn = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this._capsInitialCheck) {
      this._capsLockOn = event.getModifierState('CapsLock');
    }
    if (this.isShiftKey(event)) {
      this._shiftOn = true;
    } else if (this.checkCapLocks(event) && event.getModifierState('CapsLock')) {
      UIHelpers.growlInfo('Caps Lock On!');
    }
  }

  /** unique identifier for the key based on the letter set */
  get keyId(): string {
    return this.letter ? UIHelpers.idForKey(this.letter) : 'unknown_key';
  }

  get displayText(): string {
    return this.altText || this.letter;
  }

  /** makes the key square proportionally to the height */
  private squareUpKey() {
    const e = this.key.nativeElement;
    // e.width(e.height() * this.widthMultiplier);
    // e.css('font-size', `${e.height() * 0.5}px !important`);
    // e.css('line-height', `${e.height()}px !important`);
    // e.css('max-width', `${e.height() * 2.0}px !important`);
    this.renderer.setStyle(e, 'width', `${e.clientHeight * this.widthMultiplier}px`);
    this.renderer.setStyle(e, 'font-size', `${e.clientHeight * 0.5}px !important`);
    this.renderer.setStyle(e, 'line-height', `${e.clientHeight}px !important`);
    this.renderer.setStyle(e, 'max-width', `${e.clientHeight * 2.0}px !important`);
  }

  private isShiftKey(event: KeyboardEvent) {
    return event.keyCode === 16;
  }

  private isCapsLockKey(event: KeyboardEvent) {
    return event.keyCode === 20;
  }

  keyclick() {
    this.keyPressed.emit(this.letter);
  }

  private checkCapLocks(event: KeyboardEvent) {
    if (!this._capsInitialCheck) {
      this._capsInitialCheck = true;
      return this._capsLockOn;
    }
    if (this.isCapsLockKey(event) && this._capsLockOn) {
      this._capsLockOn = false;
    } else if (this.isCapsLockKey(event)) {
      this._capsLockOn = true;
    }
    return this._capsLockOn;
  }
}
