import { animate, keyframes, state, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

export const fadeInFadeOutAnimation: AnimationTriggerMetadata = trigger(
  'fadeInFadeOut',
  [
    transition(
      ':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave', [
        style({ opacity: 1, width: '100%' }),
        animate('500ms ease-in', style({ opacity: 0 }))
      ]
    ),
    transition(
      '0 => 1', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]
    ),
    transition(
      '1 => 0', [
        style({ opacity: 1, width: '100%' }),
        animate('500ms ease-in', style({ opacity: 0 }))
      ]
    )
  ]
);


export const fadeInAnimation: AnimationTriggerMetadata = trigger(
  'fadeIn',
  [
    transition(
      ':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]
    ),
    transition(
      '0 => 1', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]
    ),
    transition(
      '1 => 0', [
        style({ opacity: 1, width: '100%' }),
        animate('500ms ease-in', style({ opacity: 0 }))
      ]
    )
  ]
);

export const slideInSlideOutAnimation: AnimationTriggerMetadata = trigger(
  'slideInSlideOut',
  [
    transition(
      ':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('500ms ease-in', style({ transform: 'translateY(0)' }))
      ]
    ),
    transition(
      ':leave', [
        style({ transform: 'translateY(0)' }),
        animate('500ms ease-in', style({ transform: 'translateY(100%)' }))
      ]
    )]
);

export const fadeInFadeOutSlideAnimation: AnimationTriggerMetadata = trigger(
  'fadeInFadeOutSlide',
  [
    state('1', style({ opacity: 1, transform: 'translateY(0)' })),
    transition(
      ':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ opacity: 1, transform: 'translateY(0)' }))
      ]
    ),
    transition(
      ':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('500ms ease-in', style({ opacity: 0, transform: 'translateY(100%)' }))
      ]
    ),
    transition(
      '0 => 1', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('500ms ease-in', style({ opacity: 1, transform: 'translateY(0)' }))
      ]
    ),
    transition(
      '1 => 0', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('500ms ease-in', style({ opacity: 0, transform: 'translateY(100%)' }))
      ]
    )
  ]
);

export const fadeInFadeOutCollapseAnimation: AnimationTriggerMetadata = trigger(
  'fadeInFadeOutCollapse',
  [
    state('1', style({ opacity: 1, top: 0, left: 0, bottom: 0, right: 0 })),
    state('0', style({ opacity: 0, top: 0, left: 0, bottom: 'auto', right: 'auto' })),
    transition(
      ':enter', [
        style({ opacity: 0 }),
        animate(500, keyframes([
          style({ opacity: 0, offset: 0.0 }),
          style({ opacity: 0, top: 0, left: 0, bottom: 0, right: 0, offset: 0.1 }),
          style({ opacity: 1, offset: 1.0 })
        ]))
      ]
    ),
    transition(
      ':leave', [
        animate(500, keyframes([
          style({ opacity: 1, offset: 0.0 }),
          style({ opacity: 0, offset: 0.99 }),
          style({ opacity: 0, top: 0, left: 0, bottom: 'auto', right: 'auto', offset: 1.0 }),
        ]))
      ]
    ),
    transition(
      '0 => 1', [
        style({ opacity: 0 }),
        animate(500, keyframes([
          style({ opacity: 0, offset: 0.0 }),
          style({ opacity: 0, top: 0, left: 0, bottom: 0, right: 0, offset: 0.1 }),
          style({ opacity: 1, offset: 1.0 })
        ]))
      ]
    ),
    transition(
      '1 => 0', [
        animate(500, keyframes([
          style({ opacity: 1, offset: 0.0 }),
          style({ opacity: 0, offset: 0.99 }),
          style({ opacity: 0, top: 0, left: 0, bottom: 'auto', right: 'auto', offset: 1.0 }),
        ]))
      ]
    )
  ]
);

// eslint-disable-next-line max-len
export function getFadeInFadeOutSetWidthTrigger(time: string, name = 'fadeInFadeOutSetWidth'): AnimationTriggerMetadata {
  return trigger(
    name,
    [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave', [
          style({ opacity: 1 }),
          animate(`${time}ms ease-in`, style({ opacity: 0 }))
        ]
      ),
      transition(
        '0 => 1', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        '1 => 0', [
          style({ opacity: 1 }),
          animate(`${time}ms ease-in`, style({ opacity: 0 }))
        ]
      )
    ]
  );
}

export function getFadeInFadeOutTrigger(time: string, name = 'fadeInFadeOut'): AnimationTriggerMetadata {
  return trigger(
    name,
    [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        ':leave', [
          style({ opacity: 1, width: '100%' }),
          animate(`${time}ms ease-in`, style({ opacity: 0 }))
        ]
      ),
      transition(
        '0 => 1', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        '1 => 0', [
          style({ opacity: 1, width: '100%' }),
          animate(`${time}ms ease-in`, style({ opacity: 0 }))
        ]
      )
    ]
  );
}

export function getFadeInTrigger(time: string, name = 'fadeInFadeOut'): AnimationTriggerMetadata {
  return trigger(
    name,
    [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        '0 => 1', [
          style({ opacity: 0 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
    ]
  );
}


export const slideOutSlideBackInAnimation: AnimationTriggerMetadata = trigger(
  'slideOutSlideBackIn',
  [
    transition(
      'void => *', [
        // style({ transform: 'translateY(0%)' }),
        // animate('500ms ease-in', style({ transform: 'translateY(0)' }))
      ]
    ),
    transition(
      '* => *', [
        style({ transform: 'translateX(0)' }),
        animate(1500, keyframes([
          style({ transform: 'translateX(0%)', offset: 0.0 }),
          style({ transform: 'translateX(-200%)', offset: 0.49 }),
          style({ transform: 'translateX(200%)', offset: 0.50 }),
          style({ transform: 'translateX(0%)', offset: 1.0 })
        ]))
      ]
    )]
);

export function dummyTrigger(time: string, name = 'dummyAnimation'): AnimationTriggerMetadata {
  return trigger(
    name,
    [
      transition(
        ':enter', [
          style({ opacity: 1 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
      transition(
        '0 => 1', [
          style({ opacity: 1 }),
          animate(`${time}ms ease-in`, style({ opacity: 1 }))
        ]
      ),
    ]
  );
}

export const flipOutAnimation: AnimationTriggerMetadata = trigger(
  'flipOut',
  [
    // these properties are needed so the back side element flipping in doesn't show and cover the elment flipping out
    state('*', style({
      'transform-style': 'preserve-3d'
    })),
    transition(
      ':leave', [
        style({ transform: 'rotateY(0)' }),
        animate('1000ms ease-in', style({ transform: 'rotateY(180deg)' }))
      ]
    )]
);

export const flipInAnimation: AnimationTriggerMetadata = trigger(
  'flipIn',
  [
    // these properties are needed so the back side element flipping in doesn't show and cover the elment flipping out
    state('*', style({
      'transform-style': 'preserve-3d',
      // for the flip to work, the element flipping in needs to be in the same spot as the element flipping out
      // adding the style here for convienience but there may be times
      // where this doesn't work because of within parent element
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    })),
    transition(
      ':enter', [
        style({ transform: 'rotateY(180deg)' }),
        animate('1000ms ease-in', style({ transform: 'rotateY(0)' }))
      ]
    )]
);
