import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { KeyValue } from '@models/key-value';
import * as _ from 'lodash';

import { getFadeInFadeOutTrigger } from '../../../animations';

@Component({
  selector: 'zbdr-dnd-a11y-keyboard',
  templateUrl: './dnd-a11y-keyboard.component.html',
  styleUrls: ['./dnd-a11y-keyboard.component.scss'],
  animations: [getFadeInFadeOutTrigger('100')],
  encapsulation: ViewEncapsulation.None,
})
export class DndA11yKeyboardComponent implements OnInit {
  enabled = false;
  isOneItem = false;

  @Input() id: string;

  @Input() title: string;

  @Input() selected = -1;

  @Input() items: KeyValue<string>[];

  @Input() dragData: any;

  @Input() type: string = 'replace';

  /**
   * Emits a CustomEvent with the detail object containing the index, selection and dragData.
   */
  @Output() selectionPressed = new EventEmitter();

  @Output() canceled = new EventEmitter();

  // The ARIA attribute is not updating when setting the value from the template in Safari.
  // So this reference is used to set the attribute dynamically using native element API.
  @ViewChild('dialog', { static: true }) dialog: ElementRef;

  ngOnInit() {
    if (!this.id) {
      this.id = _.uniqueId('dndA11yKeyboard');
    }
    this.reset();
  }

  open(): void {
    this.isOneItem = this.items.length === 1;
    this.enabled = true;
    this.dialog.nativeElement.setAttribute('aria-hidden', 'false');
    this.dialog.nativeElement.focus();
    this.reset();
  }

  close(selected: boolean = false): void {
    this.enabled = false;
    this.dialog.nativeElement.setAttribute('aria-hidden', 'true');
    this.selected = -1;

    if (!selected) {
      this.canceled.emit(true);
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const { code } = event;
    if (code === '27') {
      this.close();
    }
  }

  handleSelection(item: KeyValue<string>) {
    // Fake the drag event.
    const event: CustomEvent = new CustomEvent('selectionPressed',
      { detail: { index: item.key, selection: item.value, dragData: this.dragData } }
    );
    this.selectionPressed.emit(event);
    this.close(true);
  }

  setActive(index: number) {
    this.selected = index;
  }

  getContent(item: KeyValue<string>): string {
    if (this.type === 'replace') {
      const valueToTest = item.value.replace('&#x2002;&#x2002;', '');
      return valueToTest.length ? item.value : `Blank Space ${item.key}`;
    }
    return `Move to ${item.value}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLabel(item: KeyValue<string>, index: number): string {
    if (this.type === 'replace') {
      const valueToTest = item.value.replace('&#x2002;&#x2002;', '');
      return valueToTest.length ? `Replace ${item.value}` : `Blank Space #${item.key}`;
    }
    return `Move to ${item.value}`;
  }

  private reset(): void {
    this.selected = 0;
    const element = this.dialog.nativeElement.querySelector(`#${this.id}Selection0`);
    if (element) {
      element.focus();
    }
  }
}
