<div class="zbProgressWrapper d-flex flex-nowrap w-100">
  <div class="progress zbProgress w-100 my-auto" [style.height]="height">
    <div class="progress-bar{{type ? ' bg-' + type : ''}}{{animated ? ' progress-bar-animated' : ''}}{{striped ?
    ' progress-bar-striped' : ''}}" role="progressbar" [style.width.%]="getPercentValue()"
    [attr.aria-valuenow]="getValue()" aria-valuemin="0" [attr.aria-valuemax]="max">
      <span *ngIf="showValue" i18n="@@ngb.progressbar.value">{{getPercentValue()}}%</span><ng-content></ng-content>
    </div>
  </div>
  <div class="zbProgressStarWrapper">
    <img class="zbProgressStar" role="presentation" alt="progress bar star" src="assets/gum/img/star.png" [@spinStar]="getValue()">
  </div>
</div>
