/* eslint-disable import/no-mutable-exports */
/* globals JQueryStatic */
import * as bowser from 'bowser';
import * as _ from 'lodash';
import { timer } from 'rxjs';

import { Helpers } from './helpers';

export interface BlockableDocument extends JQueryStatic {
  blockUI(options?: object): void;
  unblockUI(): void;
}

export namespace UIHelpers {

  // eslint-disable-next-line prefer-const
  export let growlDefaultTimeout = 3000;

  export function block(message: any = 'Loading...', css: string = null): void {
    ($ as BlockableDocument).blockUI({ message, css: Helpers.tryParseJSON(css) });
  }

  export function unblock(): void {
    ($ as BlockableDocument).unblockUI();
  }

  export function growl(title: string, message: string = null, timeout: number = growlDefaultTimeout, alertType: string = 'dark'): void {
    // ($ as BlockableDocument).growlUI(title, message, timeout, null);
    const t = title ? `<h1>${title}</h1>` : '';
    const m = message ? `<h2>${message}</h2>` : '';
    const alert = `<div class="alert alert-${alertType}" role="alert">${t}${m}</div>`;
    ($ as BlockableDocument).blockUI({
      message: alert,
      fadeIn: growlDefaultTimeout * 0.20,
      fadeOut: growlDefaultTimeout * 0.20,
      timeout,
      showOverlay: false,
      centerY: false,
      css: {
        width: '33vw',
        top: '10px',
        left: '',
        right: '20px',
        border: 'none',
        padding: '5px',
        backgroundColor: 'transparent',
        '-webkit-border-radius': '10px',
        '-moz-border-radius': '10px',
        // opacity: 0.95
      }
    });
  }

  export function isKeyButtonPress(event: KeyboardEvent): boolean {
    return (event.keyCode && (event.keyCode === 13 || event.keyCode === 32))
      || event.code === 'Enter' || event.code === 'Space';
  }

  export function growlSuccess(title: string, message: string = null, timeout: number = growlDefaultTimeout) {
    growl(`<i class="fa fa-check text-success" aria-hidden="true"></i> ${title}`, message, timeout, 'success');
  }

  export function growlError(title: string, message: string = null, timeout: number = growlDefaultTimeout) {
    growl(`<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i> ${title}`, message, timeout, 'danger');
  }

  export function growlInfo(title: string, message: string = null, timeout: number = growlDefaultTimeout) {
    growl(`<i class="fa fa-info-circle text-secondary" aria-hidden="true"></i> ${title}`, message, timeout, 'secondary');
  }

  export function growlSuccessComplete(defualtMessage: boolean, message: string = null) {
    if (defualtMessage) {
      growlSuccess('Game complete! Your score has been saved.');
    } else {
      growlSuccess(message);
    }
  }

  /** converts a 0 to 100% score to the appropriate bootstrap color coded class string */
  export function classForScore(score: number): string {
    const roundedScore = _.round(score, 2);
    if (roundedScore >= 0.90) {
      return 'success';
    }
    if (roundedScore >= 0.50) {
      return 'warning';
    }
    return 'danger';
  }

  /** takes any character, and for characters that are special a safe string is
   *  return to use in the special character's place  */
  export function safeStringForCharacter(character: string): string {
    switch (character) {
    case null:
      return 'unknown';
    case '-':
      return 'hyphen';
    case '&nbsp;':
      return 'space';
    case '\'':
      return 'apostrophe';
    case '.':
      return 'period';
    default:
      return character;
    }
  }

  /** devises an ID for a key component with respect to the character set to the key */
  export function idForKey(character: string): string {
    return `${UIHelpers.safeStringForCharacter(character.toLocaleLowerCase())}_key`;
  }

  /** determines the length of the last character in the string  */
  export function getLastCharacterLength(text: string): number {
    let charactersToRemove = 1;
    const space = '&nbsp;';
    if (text.toLowerCase().endsWith(space)) {
      charactersToRemove = space.length;
    }

    return charactersToRemove;
  }

  /** converts non-breaking spaces to whitespace */
  export function convertNonBreakingSpacesToWhitespace(text: string): string {
    return text.replace(/&nbsp;/g, ' ').replace(/&NBSP;/g, ' ').replace(/\u00a0/g, ' ');
  }

  /** converts whitespace to non-breaking spaces */
  export function convertWhitespaceToNonBreakingSpaces(text: string): string {
    return text.replace(' ', '&nbsp;');
  }

  /** closes window. */
  export function closeWindow(): void {
    const parser = bowser.getParser(window.navigator.userAgent);
    if (parser.isBrowser('msie') || parser.isBrowser('edge')) {
      const wndw = window.self;
      wndw.open('', '_self', '');
      wndw.close();
    } else {
      // This is a hack to work around browser window.close restrictions.
      // Will only work if this window was opened via popup
      const wndw = window.open('', '_self');
      wndw.document.write('');
      timer(100).subscribe(() => {
        wndw.close();
      });
    }
  }

  /** closes window after showing confirmation. */
  export function closeWindowWithConfirmation(isStudent: boolean, message: string = 'Are you sure you want to close this activity?'): void {
    // eslint-disable-next-line no-alert
    if (confirm(message)) {
      if (isStudent) {
        window.location.href = window.location.origin;
      } else {
        closeWindow();
      }
    }
  }

  /** checks tinymce editor for the selection and toggles the style and returns the state for it. */
  export function toggleEditorSelection(styleName: string, editor: any, options?: any): boolean {
    // Only check for object properties that are in the allowed styles.
    const applied = editor.formatter.match(styleName, options);
    const selectionLength: number = editor.selection.getContent().length;

    if (applied) {
      editor.formatter.remove(styleName, { value: null }, null, true);
    } else {
      editor.formatter.apply(styleName, options);
    }

    // Returns whether a match was found.
    return selectionLength === 0;
  }
}
