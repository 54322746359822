import { Injectable } from '@angular/core';
import * as bowser from 'bowser';

@Injectable()
export class BrowserService {
  get bowser(): bowser.Parser.Parser {
    return bowser.getParser(window.navigator.userAgent);
  }

  get isSafari(): Boolean {
    return this.bowser.isBrowser('safari');
  }

  get isSingleGesture() {
    return (this.isSafari && this.bowser.satisfies({ safari: '<11' })) || this.isAndroid;
  }

  get isSafari10Below() {
    return this.isSafari && this.bowser.satisfies({ safari: '<11' });
  }

  get isIos9() {
    return this.isIos && this.bowser.satisfies({ safari: '<10' });
  }

  get unsupported(): Boolean {
    return !this.bowser.satisfies({ msie: '11', chrome: '80', firefox: '78', safari: '12' });
  }

  get isMac() {
    return this.bowser.is('macOS');
  }

  get isIE() {
    return this.bowser.isBrowser('msie', true) && !this.bowser.isBrowser('edge', true);
  }

  get isIEorEdge() {
    return this.bowser.isBrowser('edge', true) || this.bowser.isBrowser('msie', true);
  }

  get checkSafariVersions() {
    return this.isSafari && this.bowser.satisfies({ safari: '>11' }) && this.bowser.satisfies({ safari: '>=7' });
  }

  get isTablet() {
    return this.isAndroid || this.isIos;
  }

  get isAndroid() {
    return this.bowser.is('Android');
  }

  get isIos() {
    // Bowser cannot detect iOS 13 because Apple decided to mask the user agent. This is a close, won't fix bug per
    // the bowser maintainer because they do not want to depend on window/navigator.
    return this.bowser.is('iOS') || (this.isSafari && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
  }

  get isFireFox() {
    return this.bowser.isBrowser('Firefox');
  }
}
