import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'zbdr-rewrite-input',
  templateUrl: './rewrite-input.component.html',
  styleUrls: ['./rewrite-input.component.scss']
})
export class RewriteInputComponent implements AfterViewInit {
  private _content = '';


  @ViewChild('input')
    input: ElementRef<HTMLElement>;

  @Input()
    size = 1;

  @Input()
    spellcheck = true;

  @Input()
  set content(val: string) {
    this._content = val;
  }

  get content(): string {
    return this._content;
  }

  @Input()
    label = '';

  @Input()
    labelHidden = true;

  @Output()
    contentChange = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  onInput(event) {
    this.contentChange.emit(event.target.value);
  }
}
