/* eslint-disable no-param-reassign */
/* globals Remarkable */
import { escapeHtml, replaceEntities, unescapeMd } from 'remarkable/lib/common/utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const boxFencePlugin: Remarkable.Plugin = (md: Remarkable, options?: any): void => {
  /**
   * Provides a fence_custom renderer for code fences.
   *
   * ~~~bgBox blah
   * some text
   * some more text
   * ~~~
   */
  md.renderer.rules.fence_custom['bgBox'] = (tokens: any[], index: number): string => {
    const token = tokens[index];
    const fences = token.params.split(/\s+/g);
    const customClass = fences.length === 2 ? ` ${escapeHtml(replaceEntities(unescapeMd(fences[1])))}` : '';
    const lineBreak = md.renderer.rules.getBreak(tokens, index);

    // We need to recurse the content through remarkable again.
    return `<div class="bgBox${customClass}">${md.render(token.content)}</div>${lineBreak}`;
  };
};
