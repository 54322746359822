import { Component, ViewEncapsulation } from '@angular/core';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';

import { spinStar } from '../animations';

@Component({
  selector: 'zbdr-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [spinStar],
})
export class ProgressbarComponent extends NgbProgressbar { }
