import {
  animate,
  animateChild,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
  AnimationMetadata,
} from '@angular/animations';

export const blindDown: AnimationMetadata = trigger('blindDown', [
  transition('* => *', [
    style({ height: 0 }),
    animate('500ms 100ms ease-in', keyframes([
      style({ height: 0 }),
      style({ height: '25vh' }),
      style({ height: '50vh' }),
      style({ height: '75vh' }),
      style({ height: '100vh' }),
    ])),
    query('@*', [animateChild()], { optional: true }),
  ]),
]);

export const bounce: AnimationMetadata = trigger('bounce', [
  transition('* => *', [
    query('.bounce:nth-child(even)', [
      style({ opacity: 0, 'margin-top': '0' }),
      stagger('250ms', [
        animate('750ms 100ms ease-in', keyframes([
          style({ opacity: 1, 'margin-top': '-.45rem' }),
          style({ opacity: 1, 'margin-top': '0' }),
          style({ opacity: 1, 'margin-top': '.45rem' }),
          style({ opacity: 1, 'margin-top': '0' }),
          style({ opacity: 1, 'margin-top': '-.45rem' }),
          style({ opacity: 1, 'margin-top': '0' }),
        ])),
      ]),
    ]),
  ]),
]);

/** trigger to fade in / fade out elements added and removed by ngIf */
export const fadeInFadeOutTrigger: AnimationMetadata = trigger(
  'fadeInFadeOutTrigger',
  [
    transition(
      ':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]
    ),
    transition(
      ':leave', [
        style({ opacity: 1 }),
        animate('500ms', style({ opacity: 0 }))
      ]
    )]
);

export const fadeOut: AnimationMetadata = trigger(
  'fadeOut',
  [
    transition(
      ':leave', [
        style({ opacity: 1 }),
        animate('250ms ease-in', style({ opacity: 0 }))
      ]
    )
  ]
);


/**
 * When element leaves DOM, wipe out left.
 */
export const wipeOut: AnimationMetadata = trigger(
  'wipeOut',
  [
    transition(
      ':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      ],
    ),
    transition(
      '1 => 0', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
      ],
    ),
  ]
);

/**
 * Wipe in-out.
 *
 * When element enters DOM, wipe in from right.
 * When element leaves DOM, wipe out left.
 */
export const wipeInOut: AnimationMetadata = trigger(
  'wipeInOut',
  [
    transition(
      '0 => 1', [
        animate('500ms 500ms ease-in', style({ transform: 'translateX(-100%)' })),
      ],
    ),
    transition(
      ':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      ],
    ),
    transition(
      ':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms 500ms ease-in', style({ transform: 'translateX(0%)' })),
      ]
    ),
    transition(
      '1 => 0', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
      ],
    ),
  ]
);

export const fadeInFadeOut: AnimationMetadata = trigger('fadeInFadeOut', [
  state('0', style({ opacity: 0 })),
  state('1', style({ opacity: 1 })),
  transition('0 => 1', [
    animate('250ms ease-in', style({ opacity: 1 })),
  ]),
  transition('1 => 0', [
    animate('250ms ease-in', style({ opacity: 0 })),
  ]),
]);

export const slideInRight: AnimationMetadata = trigger(
  'slideInRight',
  [
    transition(
      '0 => 1', [
        style({ opacity: 1, transform: 'translateX(150%)' }),
        animate('500ms ease-in', style({ opacity: 1, transform: 'translateX(0%)', width: 'auto', height: 'auto' }))
      ]
    ),
    transition(
      '1 => 0', [
        style({ opacity: 0, transform: 'translateX(150%)', width: 'auto', height: 'auto' }),
        animate('250ms ease-in', style({ opacity: 0, transform: 'translateX(0%)' }))
      ]
    )
  ]
);

export const shake: AnimationMetadata = trigger(
  'shake',
  [
    transition('0 => 1', [
      animate('500ms ease-in', keyframes([
        style({ transform: 'translateX(10%)' }),
        style({ transform: 'translateX(-10%)' }),
        style({ transform: 'translateX(10%)' }),
        style({ transform: 'translateX(-10%)' }),
        style({ transform: 'translateX(10%)' }),
      ])),
      query('@*', [animateChild()], { optional: true }),
    ]),
  ]
);

export function glowShine(bgColor: string = '#28a745', color: string = '#fff'): AnimationMetadata {
  return trigger('glowShine', [
    transition('0 => 1', [
      style({ 'box-shadow': 'none' }),
      animate('2500ms ease-out', keyframes([
        style({ 'box-shadow': 'none', offset: 0 }),
        style({
          color,
          'box-shadow': `0 0 1rem  ${bgColor}`,
          'border-color': bgColor,
          'background-color': bgColor,
          offset: 0.1,
        }),
        style({ 'box-shadow': 'none', offset: 1 }),
      ])),
    ])
  ]);
}

export function delaySpiralOut(name: string, duration: number, delay: number = 0): AnimationMetadata {
  return trigger(name, [
    transition('0 => 1', [
      style({ opacity: 1, width: '1rem', height: '1rem', transform: 'scale(1)' }),
      animate(`${duration}ms ${delay}ms ease-in`, keyframes([
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(1) rotateZ(10deg) translate(1rem) rotateZ(-370deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(2) rotateZ(-370deg) translate(1rem) rotateZ(-730deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(3) rotateZ(-730deg) translate(2rem) rotateZ(-1090deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(5) rotateZ(-1090deg) translate(3rem) rotateZ(-1450deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(8) rotateZ(-1450deg) translate(5rem) rotateZ(-1810deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(13) rotateZ(-1810deg) translate(8rem) rotateZ(-2170deg)',
        }),
        style({
          opacity: 1,
          width: '1rem',
          height: '1rem',
          transform: 'scale(21) rotateZ(-2170deg) translate(13rem) rotateZ(-2530deg)',
        }),
      ])),
    ]),
  ]);
}

export const spinStar: AnimationMetadata = trigger('spinStar', [
  transition('* => *', [
    animate('500ms 100ms ease-in-out', keyframes([
      style({ transform: 'rotateZ(10deg)' }),
      style({ transform: 'rotateZ(100deg)' }),
      style({ transform: 'rotateZ(190deg)' }),
      style({ transform: 'rotateZ(280deg)' }),
      style({ transform: 'rotateZ(370deg)' }),
    ])),
  ]),
]);
