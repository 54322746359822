/* globals FakeTouchEvent */
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { BrowserService } from '@core/browser.service';
import * as _ from 'lodash';

import { Orientation } from '../orientation.enum';

import { DrawingColors, DrawingPage, DrawingTools, DrawingVectors } from './drawing';

@Component({
  selector: 'zbdr-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DrawingComponent implements OnInit, OnChanges {
  private _drawing = false;
  private _context: CanvasRenderingContext2D;
  private _vectors: DrawingVectors;

  @Input() id: string = '';
  @Input() tool: DrawingTools = DrawingTools.none;
  @Input() color: DrawingColors = DrawingColors.black;
  @Input() orientation: Orientation = Orientation.None;
  @Input() zoom: number = 100;
  @Input() page: DrawingPage;
  @Input() background: HTMLImageElement = null;
  @Input() zoomLevel: number;
  @Output() drawingStack: EventEmitter<DrawingPage> = new EventEmitter<DrawingPage>();
  @Output() undoStack = new EventEmitter();
  @ViewChild('canvas', { static: true }) private canvasRef: ElementRef;

  constructor(
    private browser: BrowserService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {
    if (this.browser.isTablet) {
      this.renderer.addClass(this.document.body, 'disableTabletOverscroll');
    }
  }

  ngOnInit() {
    this.zoomLevel = this.zoomLevel ?? 100;

    if (!this.id) {
      this.id = _.uniqueId('pageCanvas');
    }

    if (!this.page) {
      this.page = new DrawingPage();
    }

    // Initialize vectors and either set background and resize, or just resize so that the canvas element
    // gets width/height set based on its parent element.
    this.initializeVectors();
    if (this.background) {
      this.setBackgroundImage();
    } else {
      this.resizeCanvas();
    }

    this.drawingStack.emit(this.page);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page && !changes.page.isFirstChange() && changes.page.currentValue) {
      this.initializeVectors();
      this.resizeCanvas();
    }
    if (changes.background && !changes.background.isFirstChange() && changes.background.currentValue) {
      this.initializeVectors();
      this.setBackgroundImage();
    }
    if (changes.zoomLevel && !changes.zoomLevel.isFirstChange() && changes.zoomLevel.currentValue) {
      this.initializeVectors();
      this.resizeCanvas();
    }
  }

  get context(): CanvasRenderingContext2D {
    if (!this._context) {
      this._context = this.canvas.getContext('2d');
    }
    return this._context;
  }

  get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event: UIEvent) {
    this.resizeCanvas();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  touchend(e: TouchEvent | FakeTouchEvent) {
    if (this.browser.isTablet) {
      this._drawing = false;
    }
  }

  touchdown(e: TouchEvent | FakeTouchEvent) {
    if (this.browser.isTablet) {
      const touchPosition = this.getTouchPosition(this.canvas, e);
      this._drawing = true;
      this.addClick(touchPosition.x, touchPosition.y, false);
      this.undoStack.emit(this.page.clickX.length);
      this.draw();
    }
  }

  touchmove(e: TouchEvent | FakeTouchEvent) {
    if (this.browser.isTablet) {
      if (this.isTabletDrawing()) {
        // Disables all default/parent touch events, which is what pointer-events is supposed to do.
        e.preventDefault();
      }

      if (this._drawing && this.tool !== DrawingTools.none) {
        const touchPosition = this.getTouchPosition(this.canvas, e);
        this.addClick(touchPosition.x, touchPosition.y, true);
        this.draw();
      }
    }
  }

  /**
   * When the user clicks on canvas we record the position in an array via the addClick function.
   *
   * We set the boolean paint to true (we will see why in a sec).
   * Finally we update the canvas with the function redraw
   */
  mousedown(e: MouseEvent) {
    if (!this.browser.isTablet) {
      this.undoStack.emit(this.page.clickX.length);
      this._drawing = true;
      this.addClick(e.offsetX, e.offsetY, false);
      this.draw();
    }
  }

  /**
   * Just like moving the tip of a marker on a sheet of paper.
   *
   * We want to draw on the canvas when our user is pressing down.
   * The boolean paint will let us know if the virtual marker is pressing down on the paper or not.
   * If paint is true, then we record the value. Then redraw.
   */
  mousemove(e: MouseEvent) {
    if (!this.browser.isTablet && this._drawing && this.tool !== DrawingTools.none) {
      this.addClick(e.offsetX, e.offsetY, true);
      this.draw();
    }
  }

  mouseover(e: MouseEvent) {
    if (e.buttons === 1) {
      const touchPosition = this.getTouchPositionMouse(this.canvas, e);
      this._drawing = true;
      this.addClick(touchPosition.x, touchPosition.y, false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mouseup(e: MouseEvent) {
    if (!this.browser.isTablet) {
      this._drawing = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mouseleave(e: MouseEvent) {
    if (!this.browser.isTablet) {
      this._drawing = false;
    }
  }

  isTabletDrawing(): boolean {
    return this.browser.isTablet && this._drawing;
  }

  isDrawing(): boolean {
    return this._drawing;
  }

  isActive(): boolean {
    return this.tool !== DrawingTools.none;
  }

  clear() {
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.page = new DrawingPage();
    this.drawingStack.emit(this.page);
  }

  draw() {
    // Clear the canvas.
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);

    this.context.lineJoin = 'round';

    for (let i = 0; i < this.page.clickX.length; i++) {
      this.context.beginPath();
      this.context.globalCompositeOperation = this.page.clickErase[i] ? 'destination-out' : 'source-over';
      this.context.lineWidth = this.getPenWidth(i);

      if (this.page.clickDrag[i] && i) {
        this.context.moveTo(this.page.clickX[i - 1], this.page.clickY[i - 1]);
      } else {
        this.context.moveTo(this.page.clickX[i] - 1, this.page.clickY[i]);
      }
      this.context.lineTo(this.page.clickX[i], this.page.clickY[i]);
      this.context.closePath();
      this.context.strokeStyle = this.page.clickColor[i];
      this.context.stroke();
    }
  }

  save(): string {
    return this.canvas.toDataURL();
  }

  private addClick(x: number, y: number, dragging: boolean) {
    const adjustmentFactor = this.browser.isFireFox ? 1 : this.zoom / 100;
    const erase = this.tool === DrawingTools.erase;

    this.page.clickX.push(x / adjustmentFactor);
    this.page.clickY.push(y / adjustmentFactor);
    this.page.clickDrag.push(dragging);
    this.page.clickColor.push(this.color);
    this.page.clickErase.push(erase);
    this.drawingStack.emit(this.page);
  }

  private getTouchPosition(canvasRef: HTMLCanvasElement, touch: TouchEvent | FakeTouchEvent) {
    const rect = canvasRef.getBoundingClientRect();
    return {
      x: touch.touches[0].clientX - rect.left,
      y: touch.touches[0].clientY - rect.top
    };
  }

  private getTouchPositionMouse(canvasRef: HTMLCanvasElement, mouse: MouseEvent) {
    const rect = canvasRef.getBoundingClientRect();
    return {
      x: mouse.clientX - rect.left,
      y: mouse.clientY - rect.top
    };
  }

  private getPenWidth(index: number): number {
    if (this.page.clickErase[index]) {
      return 24;
    }

    return this.orientation === Orientation.Landscape ? 5 : 3;
  }

  private resizeCanvas(): void {
    const { width, height } = this.getCanvasDimensions();

    // Calculate scale from window resizing. These are unused at the moment, but may be needed later.
    this._vectors.ScaleH = ((height) / this._vectors.InitHeight);
    this._vectors.ScaleW = ((width) / this._vectors.InitWidth);

    this._vectors.Zoom = this.zoomLevel / 100;

    // Re-calculates the width and height.
    this.canvas.width = this.getCanvasWidth(width);
    this.canvas.height = this.getCanvasHeight(width, height);

    this.draw();
  }

  /**
   * Gets the default canvas dimensions which are determined by the parent parent element.
   *
   * The parent element is the Angular element, which will have 0 height so we need to go up one
   * parent to find the true client dimensions.
   */
  private getCanvasDimensions(): { width: number, height: number } {
    return {
      width: this.canvas.parentElement.parentElement.clientWidth,
      height: this.canvas.parentElement.parentElement.clientHeight
    };
  }

  /**
   * Calculates the canvas height.
   *
   * We must calculate the height based on the parent-parent element, orientation and zoom level, but
   * NOT based on the background because there may not be a background image and the background images
   * are wildly different and making any sort of assumption would make supplemental images too small. Then
   * we can base the canvas height on the actual background size multiplied by the zoom level.
   *
   * Otherwise the height falls back to the existing height times zoom level.
   */
  private getCanvasHeight(canvasWidth: number, canvasHeight: number): number {
    if (this.orientation === Orientation.Landscape) {
      return 8.5 * (canvasWidth / 11) * this._vectors.Zoom;
    }
    if (this.orientation === Orientation.Portrait) {
      return 11 * (canvasWidth / 8.5) * this._vectors.Zoom;
    }
    if (this.background && this.orientation === Orientation.NarrowPortrait) {
      return this.background.naturalHeight * this._vectors.Zoom * 0.8;
    }
    if (this.background && this.orientation !== Orientation.None) {
      return this.background.naturalHeight * this._vectors.Zoom;
    }

    // Otherwise just set the parent-parent element height * zoom level.
    return canvasHeight * this._vectors.Zoom;
  }

  /**
   * Calculates the canvas width.
   *
   * We must first calculate the width based on the parent-parent element, orientation and zoom level, but
   * NOT based on the background because there may not be a background image and the background images
   * are wildly different and making any sort of assumption would make supplemental images too small. Then
   * we can base the canvas width on the actual background size multiplied by the zoom level.
   *
   * Otherwise the width falls back to the existing width times zoom level.
   */
  private getCanvasWidth(canvasWidth: number): number {
    if (this.orientation === Orientation.Landscape) {
      return 11 * (canvasWidth / 11) * this._vectors.Zoom;
    }
    if (this.orientation === Orientation.Portrait) {
      return 8.5 * (canvasWidth / 8.5) * this._vectors.Zoom;
    }
    if (this.background && this.orientation === Orientation.NarrowPortrait) {
      return this.background.naturalWidth * this._vectors.Zoom * 0.8;
    }
    if (this.background && this.orientation !== Orientation.None) {
      return this.background.naturalWidth * this._vectors.Zoom;
    }

    // Otherwise just set the parent-parent element width * zoom level.
    return canvasWidth * this._vectors.Zoom;
  }

  /**
   * Initializes DrawingVectors.
   *
   * @todo Denis should document this or the DrawingVectors class.
   */
  private initializeVectors(): void {
    this._vectors = new DrawingVectors();
    this._vectors.InitWidth = this.canvas.parentElement.parentElement.clientWidth;
    this._vectors.InitHeight = this.canvas.parentElement.parentElement.clientHeight;
    this._vectors.ScaleW = 1;
    this._vectors.ScaleH = 1;
    this._vectors.Zoom = this.zoomLevel;
  }

  private setBackgroundImage(): void {
    if (this.background) {
      this.canvas.style['background-image'] = `url(${this.background.src})`;
      this._vectors = new DrawingVectors();
      this._vectors.InitWidth = this.canvas.parentElement.parentElement.clientWidth;
      this._vectors.InitHeight = this.canvas.parentElement.parentElement.clientHeight;
      this._vectors.ScaleW = 1;
      this._vectors.ScaleH = 1;
      this._vectors.Zoom = 1;
      this.resizeCanvas();
    }
  }
}
