import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PatternPrompt } from '@models/pattern-prompt';
import { SentenceCompletionData } from '@models/sentence-completion-data';
import { Word } from '@models/word';
import { Helpers } from '@shared/helpers';
import { Products } from '@shared/products.enum';
import { RandomDataService } from '@shared/random-data.service';
import _ from 'lodash';
import { interval, of, Observable, throwError, fromEvent } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { BootstrapService } from './bootstrap.service';
import { BrowserService } from './browser.service';

/**
 * Interface representing the metadata for an audio file.
 * This can be used to preload the metadata for an audio file so
 * that it can be used each time the audio file needs to be retrieved and loaded to buffer
 *
 * @property {string} name - The descriptive name of the audio file. This is primarily used for identification.
 * @property {string} path - The file path where the audio file is located.
 * This path can be a local system path or a URL.
 *
 * @usage
 * Case 1: If the audio file is located within the project asset folder:
 *  zip.mp3 => { name: 'zip', path: 'assets/sfx/zip.mp3' }
 *
 * Case 2: If the audio file is located within the asset blob storage:
 *  zip.mp3 => { name: 'zip', path: 'https://stage-zb-zbportal.azureedge.net/content/audio/zip.mp3' }
 */
export interface AudioFile {
  name: string,
  path: string
}

/**
 * Interface representing the metadata for the audio files used for a given word's audio content
 *
 * @property {AudioFile} audio q- The metadata for the word.
 * @property {AudioFile} context_sentence - The metadata for the word used in a sentence.
 * @property {AudioFile} posttest_context_sentence - The metadata for the word used in
 *  a sentence designed for a PostTest.
 * @property {AudioFile} spelled_out - The metadata for the word spelled out.
 */
export interface WordAudio extends _.Dictionary<any> {
  audio: AudioFile;
  context_sentence?: AudioFile;
  posttest_context_sentence?: AudioFile;
  spelled_out?: AudioFile;
}

/**
 * Interface representing the metadata for the audio files used for a given sentence's audio content
 *
 * @property {AudioFile} sentence_audio - The metadata for the sentence.
 * @property {AudioFile} answer_audio - The metadata for the correct word.
 * @property {AudioFile} correct_sentence_audio - The metadata for the sentence including the correct word.
 */
export interface SentenceAudio extends WordAudio {
  sentence_audio: AudioFile;
  answer_audio: AudioFile;
  correct_sentence_audio: AudioFile;
}

/**
 * Interface representing the metadata for the audio files used for a given prompt's audio content
 *
 * @property {AudioFile} prompt - The metadata for prompt.
 * @property {AudioFile} feedback - The metadata for the feedback.
 * @property {AudioFile} instructions - The audio metadata for the instructions.
 * @property {AudioFile} corrected - The audio metadata for the corrected answer.
 * @property {AudioFile} correct - The audio metadata for the correct answer.
 * @property {AudioFile} distractor1 - The audio metadata for the first incorrect word used as a distractor.
 * @property {AudioFile} distractor2 - The audio metadata for the second incorrect word used as a distractor.
 */
export interface PromptAudio extends _.Dictionary<any> {
  prompt: AudioFile;
  feedback: AudioFile;
  instructions: AudioFile;
  corrected: AudioFile;
  correct: AudioFile;
  distractor1: AudioFile;
  distractor2: AudioFile;
}

export interface ISfxService {
  playInQueue(element: AudioFile): Observable<boolean>;
}

/**
 * Injectable sound effects service class for loading, playing, queuing, and managing audio files in the application.
 *
 * The nuance of "loading" in this service is specified by the method name or documentation whether
 * something is being "loaded" as metadata or as an actual audio buffer source.
 *
 * This service makes use of WebAudioApi which documentation can be found here:
 * MDN: https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Using_Web_Audio_API
 * Google Web Audio FAQ: https://developer.chrome.com/blog/web-audio-faq
 */
@Injectable()
export class SfxService implements ISfxService {
  path = '';
  audioContext: AudioContext;

  /**
   * Key: Name of the audio file (string).
   * Value: An object consisting of the decoded audio data ('buffer') and
   * a source node ('sourceNode') associated with the audio file.
   *
   * @usage
   * This acts as a storage for the decoded audio data and their respective source nodes.
   * It's used to efficiently manage and access the audio buffers by their names (keys).
   * The 'buffer' holds the decoded audio data which can be played back.
   * The 'sourceNode' is used to start, stop, and control playback.
   */
  audioBuffer: Map<string, { buffer: AudioBuffer, sourceNode: AudioBufferSourceNode }> = new Map();

  /**
   * An object map where the keys are words and the values are 'WordAudio' instances.
   *
   * @property key {string} - A word which will act as the key in the object map.
   * @property WordAudio {WordAudio} - An instance of 'WordAudio' that contains the
   *                                   corresponding audio file metadata for the word.
   */
  words: { [key: string]: WordAudio } = {};

  /**
   * An object map that stores references to different 'AudioFile' sound audio metadata instances.
   *
   * @property key {string} - The key is the identifier of a specific sound (e.g., 'correctTone', 'incorrectTone').
   * @property AudioFile {AudioFile} - The value is an instance of 'AudioFile' that represents the
   *                                   audio file associated with a specific sound.
   */
  sounds: { [key: string]: AudioFile } = {
    correctTone: null,
    incorrectTone: null,
    incorrect: null,
    empty: null
  };

  /**
   * Private object map storing SentenceAudio metadata instances, indexed by sentence key strings.
   *
   * @property key {string} - A sentence which serves as the key in the object map.
   * @property SentenceAudio {SentenceAudio} - The instance of 'SentenceAudio' that contains the
   *                                           corresponding audio file for the sentence.
   */
  private _sentences: { [key: string]: SentenceAudio } = {};

  prompts: PromptAudio[] = [];

  /**
   *  The audio queue is used for audio elements that need to be stopped.
   *
   *  See function playInQueue for implementation.
   */
  audioQueue: AudioBufferSourceNode[] = [];


  constructor(
    private bootstrap: BootstrapService,
    private browserService: BrowserService,
    private http: HttpClient,
    public random: RandomDataService) {
    this.audioContext = new AudioContext();
    this.path = `${bootstrap.baseUrl}/assets/spelling/sfx/`;
  }

  /**
   * Clears all audio sources and audio buffers.
   *
   * @returns {void}
   *
   * @usage
   * This function is called when all audio buffers and sources need to be cleaned entirely,
   * for instance, during component deinitialization or when a complete refresh is required.
   * All audio sources are stopped before clearing the audio buffer map.
   *
   * @case: Used when you want to ensure that all audio is stopped and all audio resources are released.
   */
  unload(): void {
    // Stop all currently playing audio sources
    this.audioBuffer.forEach(({ sourceNode }) => {
      // Just in case if the audio is currently playing
      if (sourceNode) {
        sourceNode.stop();
      }
    });

    // Clear the map
    this.audioBuffer.clear();
  }

  get sentences() {
    return this._sentences;
  }


  /**
   * Determines the appropriate audio file for a provided symbol.
   *
   * @param letter {string} - The string value for which corresponding AudioFile object should be found.
   *
   * @returns {AudioFile} - The AudioFile object corresponding to the input string if found, else null.
   *
   * @usage
   * This function is used to map an input string value (a letter or a special character) to
   * the corresponding AudioFile object, which is then returned. If the string value does not match
   * any key in the 'sounds' array or object, null is returned.
   *
   * @case1: If the input string is a whitespace, the function maps it to the 'space'
   * key of the 'sounds' array or object.
   * @case2: If the input string is a hyphen, the function maps it to the 'hyphen' key of the 'sounds' array or object.
   * @case3: If the input string is a period, the function maps it to the 'period' key of the 'sounds' array or object.
   * @case4: If the input string is an apostrophe, the function maps it to the 'apostrophe'
   * key of the 'sounds' array or object.
   * @case5: If the input string is any other character, the function uses it directly as
   * the key into the 'sounds' array or object.
   */
  getLetter(letter: string): AudioFile {
    let letterKey: string;
    switch (letter) {
    case ' ':
      letterKey = 'space';
      break;
    case '-':
      letterKey = 'hyphen';
      break;
    case '.':
      letterKey = 'period';
      break;
    case '\'':
      letterKey = 'apostrophe';
      break;
    default:
      letterKey = letter;
    }

    if (this.sounds[letterKey]) {
      return this.sounds[letterKey];
    }
    return null;
  }

  /**
   * Method to generate the path to the audio assets within Blob Storage.
   *
   * @returns {string} - The complete path to the game audio files in blob storage.
   *
   * @usage This method uses 'getAssetsPath' to retrieve the base asset path,
   * then appends the specific directory structure
   * for the grade level (retrieved from 'this.bootstrap.grade') and the subdirectory for the game audio.
   */
  getBlobAssetsPath(): string {
    const assetPath = this.getAssetsPath();
    return `${assetPath}grade${this.bootstrap.grade}/spcn2020/games/audio/`;
  }

  getAssetsPath(): string {
    return `${this.bootstrap.mediaUrl}content/`;
  }

  /**
   * Loads the audio file data to a buffer for playbacks.
   *
   * @param audioFile {AudioFile} - The audio file to be loaded for buffer.
   *
   * @returns {Observable<boolean>} - An observable which will return true if the audio was successfully
   * loaded to the buffer, else throws an error.
   *
   * @usage
   * This function checks if the audio for a given identifier/name is already available in the audioBuffer.
   * If not, it retrieves the audio file data via an HTTP GET request. Once the request is complete,
   * it decodes the audio data, creates an audio buffer, then maps the buffer to the audio file name.
   * If any error occurs during the process, it is logged and re-thrown.
   *
   * @case: If the audio buffer for the 'audioFile.name' is already available, it simply returns an Observable of true.
   */
  loadAudioFileToBuffer(audioFile: AudioFile): Observable<boolean> {
    if (!this.audioBuffer.get(audioFile.name)?.buffer) {
      // reload the audio
      return this.http.get(audioFile.path, { responseType: 'arraybuffer' }).pipe(
        switchMap(arrayBuffer => this.audioContext.decodeAudioData(arrayBuffer)),
        map((buffer) => {
          this.audioBuffer.set(audioFile.name, { buffer, sourceNode: null });
          return true;
        }),
        catchError((error) => {
          console.error(`There was an issue loading ${audioFile.name} from ${audioFile.path}.`, error);
          return throwError(error);
        })
      );
    }

    return of(true);
  }

  /**
   * Plays an audio file directly without adding it to the queue.
   *
   * @param audioFile {AudioFile} - The audio file to be played.
   *
   * @returns {Observable<boolean>} - An Observable which emits 'true' when the audio playback
   * has ended or no audio source was available.
   *
   * @usage
   * This function serves to play audio files. It first attempts to load the audio file to the buffer.
   * If the loading is successful, it starts playback of the audio file using the 'startAudio' function.
   *
   * @case1: If the audio file is successfully loaded into the buffer, the audio is played and 'true'
   * is emitted after play has ended.
   * @case2: If loading the audio file into the buffer fails, it immediately emits 'true' as 'startAudio'
   * is responsible for handling non-existence of audio source.
   */
  play(audioFile: AudioFile): Observable<boolean> {
    return this.loadAudioFileToBuffer(audioFile).pipe(
      switchMap(() => this.startAudio(audioFile))
    );
  }

  /**
   * Queues an audio file to be played.
   *
   * @param audioFile {AudioFile} - The audio file to be played.
   *
   * @returns {Observable<boolean>} - An observable which will return true if the audio was
   * successfully queued and played, else false.
   *
   * @usage
   * This function, when called, stops any currently playing audio queue, loads the passed audio file,
   * and creates a buffer source for it. The loaded audio buffer is then connected to the
   * audio context's destination (the audio-rendering device), and the audio file is played.
   * If successful in playing the audio, it pushes the source node to the audio queue,
   * or else it returns an observable of false.
   *
   * @case1: If there is an existing audio queue, it stops that queue before processing the passed audioFile.
   * @case2: If unable to load the audio file, it would return an Observable of false.
   */
  playInQueue(audioFile: AudioFile): Observable<boolean> {
    if (this.audioQueue) {
      this.stopQueue();
    }

    return this.loadAudioFileToBuffer(audioFile).pipe(
      mergeMap((loaded) => {
        if (loaded) {
          const buffer =  this.audioBuffer.get(audioFile.name)?.buffer;
          const sourceNode = this.audioContext.createBufferSource();
          sourceNode.buffer = buffer;
          this.audioBuffer.set(audioFile.name, { buffer, sourceNode });
          sourceNode?.connect(this.audioContext.destination);
          sourceNode?.start();
          return of(true);
        }

        return of(false);
      }),
      mergeMap((played) => {
        if (played) {
          const source = this.audioBuffer.get(audioFile.name)?.sourceNode;
          this.audioQueue.push(source);
          return of(true);
        }

        return of(false);
      })
    );
  }

  stopQueue() {
    if (this.audioQueue.length > 0) {
      this.audioQueue.forEach(queue => queue.stop());
      this.audioQueue = [];
    }
  }

  /**
   * Handles the logic to play an audio file, either directly or enqueued.
   *
   * @param audioFile {AudioFile} - The audio file to be played.
   * @param enqueue {boolean} - Determines if the audio file should be added to the queue for playback.
   * Default is false.
   *
   * @returns {Observable<boolean>} - An Observable which emits 'true' when the audio playback has ended.
   *
   * @usage
   * This function serves as a wrapper for the 'playInQueue' and 'play' functions,
   * deciding which one to use based on the 'enqueue' parameter. If 'enqueue' is true,
   * 'playInQueue' is used to play the audio file; otherwise, the 'play' function is invoked.
   *
   * @case1: If 'enqueue' parameter is true, 'playInQueue' method is used.
   * @case2: If 'enqueue' parameter is false or not provided, 'play' method is used.
   */
  playAudio(audioFile: AudioFile, enqueue: boolean = false) : Observable<boolean> {
    return enqueue ? this.playInQueue(audioFile) : this.play(audioFile);
  }

  /**
   * Handles the playing of two audio files in a sequence with a delay between them.
   *
   * @param first {AudioFile} - The first audio file to be played in sequence.
   * @param second {AudioFile} - The second audio file to be played after the first audio file.
   * @param delay {number} - The delay in milliseconds between the playback of the
   * first and second audio files. Default is 0.
   * @param enqueue {boolean} - If true, the audios will be queued to play in
   * sequence using the 'playInQueue' method, otherwise, the 'play' method is used. Default is false.
   * @param fallbackTo {number} - It determines the file to be played in a single gesture scenario.
   * If it's 1, the 'second' file will be played; otherwise, the 'first' file will be played. Default is 1.
   *
   * @returns {Observable<boolean>} - An Observable which emits 'true' when either the sequence playback
   * has ended or when a file in the sequence has been played.
   *
   * @usage
   * This function allows to play two audio files in sequence. The delay between the files, whether the
   * files should be enqueued and the fallback option can be specified. In case of a single gesture
   * scenario detected by 'browserService.isSingleGesture', only the fallback file will be played.
   *
   * @case1: If 'enqueue' is true, it plays the audio files in sequence
   * using the 'playInQueue' method of the current object.
   * @case2: If 'enqueue' is false, it plays the audio files in sequence using the 'play' method of the current object.
   * @case3: If 'isSingleGesture' is detected to be true by the 'browserService',
   * only the 'fallback' audio file will be played.
   */
  playAudioSequence(first: AudioFile, second: AudioFile, delay: number = 0, enqueue: boolean = false, fallbackTo: number = 1): Observable<boolean> {
    const playMethod = enqueue ? 'playInQueue' : 'play';
    const { isSingleGesture } = this.browserService;
    const fallback = !isSingleGesture || fallbackTo === 1 ? second : first;

    return (!isSingleGesture ? this[playMethod](first) : of(true))
      .pipe(
        mergeMap(() => interval(delay).pipe(map(() => true), take(1))),
        mergeMap(() => this[playMethod](fallback))
      );
  }

  /**
   * Starts the audio playback for a given audio file.
   *
   * @param audioFile {AudioFile} - The object containing audio file properties like name and path.
   *
   * @returns {Observable<boolean>} - An Observable which emits 'true' when either the audio playback
   * has ended or when there was no audio source to begin with.
   *
   * @usage
   * A method to begin the playback of an audio file. The method uses the 'audioFile' parameter to
   * fetch the source node from the audioBuffer, if it exists.
   * It then connects the source node to the audio context destination, starts the audio playback,
   * and sets the 'hasBeenPlayed' property of the audio file to true.
   * In case of non-existence of the source node in the buffer, the method simply returns an
   * Observable producing a single 'true' value.
   * This method is ideal for manually starting the audio play.
   *
   * @case1: If the audio resource does not exist in the buffer, the method will simply return an
   * Observable with 'true' without trying to play the audio.
   * @case2: If the audio resource is fetched and played, upon ending of the playback, 'true' is emitted.
   */
  startAudio(audioFile: AudioFile): Observable<boolean> {
    const buffer =  this.audioBuffer.get(audioFile.name)?.buffer;
    const sourceNode = this.audioContext.createBufferSource();
    sourceNode.buffer = buffer;
    this.audioBuffer.set(audioFile.name, { buffer, sourceNode });

    if (sourceNode) {
      sourceNode?.connect(this.audioContext.destination);
      sourceNode?.start();
      // this.audioBuffer.get(audioFile.name).hasBeenPlayed = true;
      return fromEvent(sourceNode, 'ended').pipe(take(1), map(() => true));
    }

    return of(true);
  }

  /**
   * Plays an incorrect tone and then spells out a word.
   *
   * @param word {string} - The word to be spelled out.
   * @param enqueue {boolean} - If true, the audio files will be added to a playback queue. Default is false.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the audio playback has ended.
   *
   * @usage
   * This function initiates the playback of an 'incorrectTone' followed by 'incorrect'
   * audio and then the spelling out of a specified word. If the 'isSingleGesture' property of
   * the 'browserService' is true, it skips the playback of the 'incorrectTone' and 'incorrect'
   * audio and immediately proceeds with spelling out the word. Both 'incorrectTone' and
   * 'incorrect' audio files are selected based on the value of the 'enqueue' parameter.
   * If 'enqueue' is true, the files will be added to the queue before playing.
   *
   * @case1: If 'isSingleGesture' is false, it starts by playing the 'incorrectTone',
   * followed by 'incorrect' audio and then spells the word.
   * @case2: If 'isSingleGesture' is true, it immediately proceeds with spelling the word,
   * skipping the 'incorrectTone' and 'incorrect' audio.
   */
  playIncorrectToneAndThenSpellWord(word: string, enqueue: boolean = false): Observable<boolean> {
    const playMethod = enqueue ? 'playInQueue' : 'play';
    return (!this.browserService.isSingleGesture ? this[playMethod](this.sounds.incorrectTone) : of(true))
      .pipe(
        mergeMap(() => (!this.browserService.isSingleGesture ? this[playMethod](this.sounds.incorrect) : of(true))),
        mergeMap(() => this[playMethod](this.words[word].spelled_out))
      );
  }

  /**
   * Plays the audio corresponding to the passed word.
   *
   * @param word {string} - The word whose audio needs to be played.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the audio playback starts, 'false' otherwise.
   *
   * @usage
   * This function attempts to play the audio for the specified word. It first checks if the word and
   * its corresponding audio exist. If they do, it plays the audio. If the word is not found,
   * or if the audio for the word is not available, it logs an error message and returns an Observable of 'false'.
   *
   * @case1: If the word exists and the corresponding audio is available, the audio is played
   * and an Observable of 'true' is returned.
   * @case2: If the word does not exist in the 'words' object, it logs an error message,
   * and an Observable of 'false' is returned.
   * @case3: If the word exists but its corresponding audio is unavailable, it logs an error message,
   * and an Observable of 'false' is returned.
   */
  playWord(word: string): Observable<boolean> {
    const w = this.words[word];

    if (w && w.audio) {
      return this.play(w.audio);
    }
    if (!w) {
      console.error(`No audio metadata for the word ${word} has been preloaded and therefore cannot be played.`);
      return of(false);
    }
    if (!w.audio) {
      console.error(`The audio metadata for the word ${word} cannot be loaded and therefore cannot be played.`);
      return of(false);
    }

    return of(false);
  }

  /**
   * Plays the context sentence audio corresponding to the passed word.
   *
   * @param word {string} - The word whose context sentence audio needs to be played.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the context sentence audio playback starts,
   * 'false' otherwise.
   *
   * @usage
   * This function attempts to play the context sentence audio for the specified word.
   * It first checks if the word and its corresponding context sentence audio exist.
   * If they do, it adds the audio to the playback queue.
   * If the word is not found, or if the context sentence audio for the word is not available,
   * it logs an error message and returns an Observable of 'false'.
   *
   * @case1: If the word exists and the corresponding context sentence audio is available,
   * the audio is added to the playback queue and an Observable of 'true' is returned.
   * @case2: If the word does not exist in the 'words' object, it logs an error message,
   * and an Observable of 'false' is returned.
   * @case3: If the word exists but its corresponding context sentence audio is unavailable,
   * it logs an error message, and an Observable of 'false' is returned.
   */
  playWordContext(word: string): Observable<boolean> {
    const w = this.words[word];
    if (w && w.context_sentence) {
      return this.playInQueue(w.context_sentence);
    }
    if (!w) {
      console.error(`No context audio metadata for the word ${word} has been preloaded and therefore cannot be played.`);
      return of(false);
    }
    if (!w.audio) {
      console.error(`The context audio file metadata the word ${word} cannot be loaded and therefore cannot be played.`);
      return of(false);
    }

    return of(false);
  }

  /**
   * Plays the audio corresponding to the given letter.
   *
   * @param letter {string} - The letter whose corresponding audio needs to be played.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the letter's audio playback begins.
   *
   * @usage
   * This function initiates the playback of the audio corresponding to the specified letter.
   * It retrieves the audio for the letter from the 'sounds' object and initiates the playback.
   *
   * @case: If the letter's audio is available,
   * it plays the audio and returns an Observable that emits 'true' when the playback begins.
   */
  playLetter(letter: string): Observable<boolean> {
    return this.play(this.sounds[letter]);
  }

  /**
   * Plays the sentence audio of the given word.
   *
   * @param word {string} - The word whose sentence audio needs to be played.
   * @param complete {boolean} - If set to true, plays the 'correct_sentence_audio'.
   * Otherwise, plays the 'sentence_audio'. Default is false.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the sentence audio playback begins.
   *
   * @usage
   * This function initiates the playback of the sentence audio for the specified word.
   * It retrieves the type of audio based on the 'complete' flag.
   * If 'complete' is true, it plays the 'correct_sentence_audio'; otherwise, it plays the 'sentence_audio'.
   * The audio is then added to the playback queue.
   *
   * @case: Based on the 'complete' flag, it plays the corresponding sentence audio and
   * returns an Observable that emits 'true' when the playback begins.
   */
  playSentence(word: string, complete: boolean = false): Observable<boolean> {
    const type = complete ? 'correct_sentence_audio' : 'sentence_audio';
    return this.playInQueue(this._sentences[word][type]);
  }

  /**
   * Spells out the given word by playing corresponding audio files.
   *
   * @param word {string} - The word to be spelled out.
   *
   * @returns {Observable<boolean>} - An Observable that emits 'true' when the spelling audio starts playing.
   *
   * @usage
   * This function initiates the spelling out of the specified word.
   * It retrieves the spelling audio for the word from the 'words' object and adds it to the playback queue.
   *
   * @case: It plays the spelling audio for the specified word and
   * returns an Observable that emits 'true' when the playback begins.
   */
  spellWord(word: string): Observable<boolean> {
    return this.playInQueue(this.words[word].spelled_out);
  }

  /**
   * Prepares the collection of audio file metadata for specific products and grades.
   *
   * @param product {Products} - The product for which the metadata needs to be prepared.
   * @param grade {string} - The grade level for the audio files.
   *
   * @returns {void} - This function does not return a value.
   *
   * @usage
   * This function is used to load and prepare the metadata for given products and grades.
   * For the products 'spelling' and 'gum', it loads default metadata and assigns specific metadata
   * (letters, punctuation marks, and special keys) to the 'sounds' array or object.
   * The audio paths are constructed using the grade and product-specific paths.
   * When the product is 'spelling', it includes all alphabet letters, punctuation marks,
   * and sounds for 'space', 'backspace', 'done'.
   *
   * @case1: If the product is either 'spelling' or 'gum', the default metadata is loaded,
   * and for the product 'spelling', specific sound files are assigned.
   * @case2: If the product is 'spelling' and not 'gum', all keyboard sound metadata is loaded
   * @case3: If the product is neither 'spelling' nor 'gum', no metadata is loaded.
   */
  prepareProductAudioMetadataCollection(product: Products, grade: string): void {
    if (product === Products.spelling || product === Products.gum) {
      // Autoload the letter files and audio elements for spelling.
      const letterPath = `${this.path}grade${this.bootstrap.grade}/keyboard/`;
      this.loadDefaultAudioMetadata();

      if (product === Products.spelling) {
        this.sounds['a'] = { name: 'a', path: `${letterPath}letter_a-g${grade}.mp3` };
        this.sounds['apostrophe'] = { name: 'apostrophe', path: `${letterPath}letter_apostrophe-g${grade}.mp3` };
        this.sounds['b'] = { name: 'b', path: `${letterPath}letter_b-g${grade}.mp3` };
        this.sounds['c'] = { name: 'c', path: `${letterPath}letter_c-g${grade}.mp3` };
        this.sounds['d'] = { name: 'd', path: `${letterPath}letter_d-g${grade}.mp3` };
        this.sounds['done'] = { name: 'done', path: `${letterPath}letter_done-g${grade}.mp3` };
        this.sounds['e'] = { name: 'e', path: `${letterPath}letter_e-g${grade}.mp3` };
        this.sounds['f'] = { name: 'f', path: `${letterPath}letter_f-g${grade}.mp3` };
        this.sounds['g'] = { name: 'g', path: `${letterPath}letter_g-g${grade}.mp3` };
        this.sounds['h'] = { name: 'h', path: `${letterPath}letter_h-g${grade}.mp3` };
        this.sounds['hyphen'] = { name: 'hyphen', path: `${letterPath}letter_hyphen-g${grade}.mp3` };
        this.sounds['i'] = { name: 'i', path: `${letterPath}letter_i-g${grade}.mp3` };
        this.sounds['j'] = { name: 'j', path: `${letterPath}letter_j-g${grade}.mp3` };
        this.sounds['k'] = { name: 'k', path: `${letterPath}letter_k-g${grade}.mp3` };
        this.sounds['l'] = { name: 'l', path: `${letterPath}letter_l-g${grade}.mp3` };
        this.sounds['m'] = { name: 'm', path: `${letterPath}letter_m-g${grade}.mp3` };
        this.sounds['n'] = { name: 'n', path: `${letterPath}letter_n-g${grade}.mp3` };
        this.sounds['o'] = { name: 'o', path: `${letterPath}letter_o-g${grade}.mp3` };
        this.sounds['p'] = { name: 'p', path: `${letterPath}letter_p-g${grade}.mp3` };
        this.sounds['period'] = { name: 'period', path: `${letterPath}letter_period-g${grade}.mp3` };
        this.sounds['q'] = { name: 'q', path: `${letterPath}letter_q-g${grade}.mp3` };
        this.sounds['r'] = { name: 'r', path: `${letterPath}letter_r-g${grade}.mp3` };
        this.sounds['s'] = { name: 's', path: `${letterPath}letter_s-g${grade}.mp3` };
        this.sounds['space'] = { name: 'space', path: `${letterPath}letter_space-g${grade}.mp3` };
        this.sounds['t'] = { name: 't', path: `${letterPath}letter_t-g${grade}.mp3` };
        this.sounds['u'] = { name: 'u', path: `${letterPath}letter_u-g${grade}.mp3` };
        this.sounds['v'] = { name: 'v', path: `${letterPath}letter_v-g${grade}.mp3` };
        this.sounds['w'] = { name: 'w', path: `${letterPath}letter_w-g${grade}.mp3` };
        this.sounds['x'] = { name: 'x', path: `${letterPath}letter_x-g${grade}.mp3` };
        this.sounds['y'] = { name: 'y', path: `${letterPath}letter_y-g${grade}.mp3` };
        this.sounds['z'] = { name: 'z', path: `${letterPath}letter_z-g${grade}.mp3` };
        this.sounds['backspace'] = { name: 'backspace', path: `${this.path}keyboard/backspace_sfx.mp3` };
      }
    }
  }

  /**
   * Loads the metadata for audio effects like correct and incorrect tones.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the paths for the 'correctTone' and 'incorrectTone' audio files into the 'sounds' object.
   * If a grade is specified in the 'bootstrap' object, it also loads the path for a grade-specific
   * 'incorrect' audio file. The 'sounds' object can then be used to retrieve and play these audio files.
   *
   * @case1: It always loads the 'correctTone' and 'incorrectTone' audio files into the 'sounds' object.
   * @case2: If a grade is specified, it also loads a grade-specific 'incorrect' audio file into the 'sounds' object.
   */
  loadAudioEffectsMetadata(): void {
    this.sounds.correctTone = { name: 'correctTone', path: `${this.path}correct_sfx.mp3` };
    this.sounds.incorrectTone = { name: 'incorrectTone', path: `${this.path}incorrect_sfx.mp3` };
    if (this.bootstrap.grade) {
      this.sounds.incorrect = { name: 'incorrect',
        path: `${this.path}grade${this.bootstrap.grade}/feedback-incorrect-g${this.bootstrap.grade}.mp3` };
    }
  }

  removeWordMetadata(word: string) {
    _.forEach(this.words, () => {
      if (this.words[word]) {
        delete this.words[word];
      }
    });
  }

  /**
   * Initiates the loading of audio metadata for a specific word based on given parameters.
   *
   * @param wordCopy {Word} - A copy of the Word object containing the word for which audio metadata is to be loaded.
   * @param includeAllContextAudio {boolean} - If set to true, includes all the context audios. Default is true.
   * @param includeContextSentence {boolean} - If set to true, includes the context sentence audio. Default is false.
   * @param includeSpelledOut {boolean} - If set to true, includes the 'spelled_out' audio. Default is false.
   * @param includePostTest {boolean} - If set to true, includes the 'postTest' audio. Default is false.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the audio metadata for the specified word.
   * The function delegates the loading process to the 'loadWordAudioMetadataFromString' function,
   * providing it with the grade info and the specific audio types to include as per the function parameters.
   */
  loadWordAudioMetadata(
    wordCopy: Word,
    includeAllContextAudio: boolean = true,
    includeContextSentence: boolean = false,
    includeSpelledOut: boolean = false,
    includePostTest: boolean = false): void {
    const word = { ...wordCopy };

    this.loadWordAudioMetadataFromString(word.word,
      this.bootstrap.grade,
      includeAllContextAudio,
      includeContextSentence,
      includeSpelledOut,
      includePostTest
    );
  }

  /**
   * Loads the metadata for specific types of audio recordings for the given word.
   *
   * @param word {string} - The word for which the audio metadata is to be loaded.
   * @param grade {string} - The grade level information.
   * @param includeContextAudio {boolean} - If set to true, includes the 'contextAudio' recordings. Default is true.
   * @param includeContextSentence {boolean} - If set to true, includes the 'contextSentence' recordings.
   * Default is false.
   * @param includeSpelledOut {boolean} - If set to true, includes the 'spelledOut' recordings. Default is false.
   * @param includePostTest {boolean} - If set to true, includes the 'postTest' recordings. Default is false.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the audio metadata for a word specified as a string. It normalizes the word filename and
   * initiates the loading of the audio metadata. The function delegates the loading process to the
   * 'loadContextAudioMetadata' function, providing it with the grade info and the specific audio types to
   * include as per the function parameters.
   *
   * @case: If the metadata for the word is already loaded, it early exits. Otherwise, it normalizes the word
   * filename and initiates the loading of the audio metadata.
   */
  loadWordAudioMetadataFromString(
    word: string,
    grade: string,
    includeContextAudio: boolean = true,
    includeContextSentence: boolean = false,
    includeSpelledOut: boolean = false,
    includePostTest: boolean = false): void {
    const index = word;
    let wordToUse = word;

    if (this.words[word]) {
      return;
    }

    wordToUse = Helpers.normalizeWordFilename(wordToUse);
    if (this.bootstrap.isLocalUrl) {
      console.log(`Loading audio for: ${wordToUse}`);
    }

    this.loadContextAudioMetadata(
      index,
      wordToUse,
      grade,
      includeContextAudio,
      includeContextSentence,
      includeSpelledOut,
      includePostTest
    );
  }

  /**
   * Loads the audio metadata for the specified set of prompts.
   *
   * @param prompt {PatternPrompt} - The prompt for which the audio metadata is to be loaded.
   *
   * @returns {void}
   *
   * @usage
   * This function prepares the metadata map for the specified 'PatternPrompt' prompts, which includes,
   * among others, the 'prompt', 'feedback', 'instructions', 'corrected', 'correct', 'distractor1',
   * and 'distractor2' audios. This metadata can be retrieved later to play the corresponding audio files.
   *
   * @case: It pushes the audio metadata of different types (like 'prompt', 'feedback', 'instructions', etc.)
   * for a specified prompt into the 'prompts' list.
   */
  loadPromptAudioMetaData(prompt: PatternPrompt): void {
    // Removes the first in the queue and unloads audio elements.
    const promptCopy: PatternPrompt = _.cloneDeep(prompt);
    const assetPath = this.getAssetsPath();

    this.prompts.push({
      prompt: { name: `prompt-${prompt.prompt}`, path: `${assetPath}${promptCopy.promptAudio}` },
      feedback: { name: `feedback-${prompt.feedback}`, path: `${assetPath}${promptCopy.feedbackAudio}` },
      instructions: { name: `instructions-${prompt.instructions}`, path: `${assetPath}${promptCopy.instructionsAudio}` },
      corrected: { name: `corrected-${prompt.correctedPrompt}`, path: `${assetPath}${promptCopy.promptCompleteAudio}` },
      correct: { name: `correct-${prompt.correct}`, path: `${assetPath}${promptCopy.correctAudio}` },
      distractor1: { name: `distractor1-${prompt.distractor1}`, path: `${assetPath}${promptCopy.distractor1Audio}` },
      distractor2: { name: `distractor2-${prompt.distractor2}`, path: `${assetPath}${promptCopy.distractor2Audio}` }
    } as PromptAudio);
  }

  /**
   * Loads the metadata for the default set of audio effects, which includes 'incorrect', 'correctTone',
   * 'incorrectTone', and 'empty' audio.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the paths for the default audio effects into the 'sounds' object.
   * These audio effects include 'incorrect', 'correctTone', 'incorrectTone', and 'empty' sounds.
   * The loaded audio metadata can then be used to retrieve and play these sounds when required.
   */
  loadDefaultAudioMetadata(): void {
    const path = `${this.path}grade${this.bootstrap.grade}/feedback-incorrect-g${this.bootstrap.grade}.mp3`;
    this.sounds.incorrect = { name: 'incorrect', path };
    this.sounds.correctTone = { name: 'correctTone', path: `${this.path}correct_sfx.mp3` };
    this.sounds.incorrectTone = { name: 'incorrectTone', path: `${this.path}incorrect_sfx.mp3` };
    this.sounds.empty = { name: 'empty', path: `${this.path}empty.mp3` };
  }

  /**
   * Loads the metadata for a specific audio effect.
   *
   * @param name {string} - The name of the audio effect.
   * @param file {string} - The filename or path of the audio file.
   * @param fromContent {boolean} - If set to true, the audio file will be loaded from the BLOB assets path.
   * Default is false.
   *
   * @returns {AudioFile} - The AudioFile object containing the loaded metadata.
   *
   * @usage
   * This function loads the metadata for a specified audio effect and associates it with the 'name' provided.
   * If given, it can load the audio file from a BLOB assets path. The loaded metadata is then stored in the
   * 'sounds' object and also returned by the function.
   *
   * @case1: If the audio metadata for the 'name' is already loaded, it simply returns the already loaded metadata.
   * @case2: If the 'fromContent' is set to true, it sets the file path to be from the BLOB assets path.
   */
  loadAudioMetadata(name: string, file: string, fromContent: boolean = false): AudioFile {
    if (!this.sounds[name]) {
      let filePath: string = file;
      if (fromContent) {
        filePath = `${this.getBlobAssetsPath()}${file}`;
      }
      this.sounds[name] = { name, path: filePath };
    }

    return this.sounds[name];
  }

  /**
   * Loads the audio metadata using audio name and a product path.
   *
   * @param name {string} - The name of the audio file.
   * @param productPath {string} - The path where the product's audio files are stored.
   * If null or not provided, defaults to the current path ('this.path').
   *
   * @returns {AudioFile} - The AudioFile object containing the loaded metadata.
   *
   * @usage
   * This function loads the metadata for a specified audio file from a specified product path.
   * If the product path is not provided, it defaults to the current path ('this.path').
   * The loaded metadata is then stored in the 'sounds' object and also returned by the function.
   *
   * @case: If the audio metadata for the 'name' is already loaded, it avoids loading again and
   * returns the existing metadata.
   */
  loadAudioMetadataFromProductPath(name: string, productPath: string = null): AudioFile {
    const path = productPath || this.path;
    if (!this.sounds[name]) {
      this.sounds[name] = { name, path: `${path}/${name}.mp3` };
    }
    return this.sounds[name];
  }

  /**
   * Loads the metadata for a sentence, word, and their related audio recordings.
   *
   * @param sentence {SentenceCompletionData} - The sentence data for which the audio metadata is to be loaded.
   * @param grade {string} - The grade level information.
   * @param includeContextAudio {boolean} - If set to true, includes the 'contextAudio' recordings. Default is true.
   * @param loadSentence {boolean} - If set to true, includes the 'sentence_audio' recordings. Default is true.
   * @param loadAnswer {boolean} - If set to true, includes the 'answer_audio' recordings. Default is true.
   * @param loadCorrect {boolean} - If set to true, includes the 'correct_sentence_audio' recordings. Default is true.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the metadata for sentence, correct answer, and correct sentence audios and also calls
   * 'loadWordAudioMetadataFromString' to load the word's audio metadata. The loaded sentence metadata consists
   * of the 'sentence_audio', 'correct_sentence_audio', and 'answer_audio' and stores this to its '_sentences' field.
   *
   * @case: It checks and decide whether to load 'sentence_audio', 'correct_sentence_audio' and 'answer_audio'
   * metadata according to the 'loadSentence', 'loadAnswer', and 'loadCorrect' values.
   */
  loadSentenceMetadata(
    sentence: SentenceCompletionData,
    grade: string,
    includeContextAudio: boolean = true,
    loadSentence: boolean = true,
    loadAnswer: boolean = true,
    loadCorrect: boolean = true): void {
    this.loadWordAudioMetadataFromString(sentence.word, grade, includeContextAudio);
    const wordAudio = this.words[sentence.word] as SentenceAudio;

    if (loadSentence) {
      wordAudio.sentence_audio = { name: `${sentence.word} sentence_audio`,
        path: `${this.getBlobAssetsPath()}${sentence.sentenceAudio}` };
    }

    if (loadCorrect) {
      wordAudio.correct_sentence_audio = { name: `${sentence.word} correct_sentence_audio`,
        path: `${this.getBlobAssetsPath()}${sentence.correctAudio}` };
    }

    if (loadAnswer) {
      wordAudio.answer_audio = { name: `${sentence.word} answer_audio`,
        path: `${this.getBlobAssetsPath()}${sentence.answerAudio}` };
    }

    this._sentences[sentence.word] = wordAudio;
  }

  /**
   * Loads the audio metadata for a given word within context.
   *
   * @param index {string} - The identifier/index for the words object.
   * @param word {string} - The word for which the audio metadata is to be loaded.
   * @param grade {string} - The grade level information.
   * @param includeAllContextAudio {boolean} - If set to true, includes all context audios.
   * @param includeContextSentence {boolean} - If set to true, includes the 'context sentence' audio.
   * @param includeSpelledOut {boolean} - If set to true, includes the 'spelled out' audio.
   * @param includePostTest {boolean} - If set to true, includes the 'post test context sentence' audio.
   *
   * @returns {void}
   *
   * @usage
   * This function loads the metadata for a given word within a specific context.
   * Depending on the boolean flag inputs, it can either include all context audio or
   * selectively include specific ones. The loaded metadata is then stored in the 'words' object.
   *
   * @case1: If 'includeAllContextAudio' is true, it includes all context audios.
   * @case2: Else, it checks and decide whether to load 'context_sentence', 'spelled_out',
   * and 'posttest_context_sentence' according to 'includeContextSentence', 'includeSpelledOut',
   * and 'includePostTest' values.
   */
  private loadContextAudioMetadata(
    index: string,
    word: string,
    grade: string,
    includeAllContextAudio: boolean,
    includeContextSentence: boolean,
    includeSpelledOut: boolean,
    includePostTest: boolean): void {
    if (includeAllContextAudio) {
      this.words[index] = {
        audio: { name: `${word}`, path: `${this.getBlobAssetsPath()}${word}-g${grade}.mp3` },
        context_sentence: { name: `${word}-context_sentence`,
          path: `${this.getBlobAssetsPath()}${word}-context-g${grade}.mp3` },
        spelled_out: { name: `${word}-spelled_out`, path: `${this.getBlobAssetsPath()}${word}-spelled-g${grade}.mp3` },
        posttest_context_sentence: { name: `${word}-posttest_context_sentence`,
          path: `${this.getBlobAssetsPath()}${word}-posttest-g${grade}.mp3` },
      };
    } else {
      this.words[index] = {
        audio: { name: `${word}`, path: `${this.getBlobAssetsPath()}${word}-g${grade}.mp3` }
      };
      if (includeContextSentence) {
        this.words[index].context_sentence = { name: `${word}-context_sentence`,
          path: `${this.getBlobAssetsPath()}${word}-context-g${grade}.mp3` };
      }
      if (includeSpelledOut) {
        this.words[index].spelled_out = { name: `${word}-spelled_out`,
          path: `${this.getBlobAssetsPath()}${word}-spelled-g${grade}.mp3` };
      }
      if (includePostTest) {
        this.words[index].posttest_context_sentence = { name: `${word}-posttest_context_sentence`,
          path: `${this.getBlobAssetsPath()}${word}-posttest-g${grade}.mp3` };
      }
    }
  }
}
