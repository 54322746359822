import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'zbdr-dnd-a11y-state-button',
  templateUrl: './dnd-a11y-state-button.component.html',
  styleUrls: ['./dnd-a11y-state-button.component.scss'],
})
export class DndA11yStateButtonComponent implements OnInit {
  private _state: boolean = false;
  id: string = '';

  @Input() buttonClasses: string = '';
  @Input() wrapperClasses: string = '';
  @Output() stateChanged: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @HostBinding('class') hostClass: string = '';

  ngOnInit() {
    this.id = _.uniqueId('a11yMenuStateButton');
    this.hostClass = this.wrapperClasses;
  }

  get enableA11yMenuLabel(): string {
    return this._state ? 'Turn Off Accessible Menu' : 'Use Accessible Menu';
  }

  get describedById(): string {
    return `${this.id}Description`;
  }

  get state(): boolean {
    return this._state;
  }

  get defaultClasses(): string {
    return `a11yMenuStateButton ${this.buttonClasses}`;
  }

  toggle(): void {
    this._state = !this._state;
    this.stateChanged.emit(this._state);
  }
}
