import { Injectable } from '@angular/core';
import Remarkable from 'remarkable';

import { alignRightPlugin } from './markdown-plugins/align-right';
import { boxFencePlugin } from './markdown-plugins/box-fence';
import { bracketPlugin } from './markdown-plugins/bracket';
import { underlinePlugin } from './markdown-plugins/underline';

@Injectable()
export class MarkdownService {
  private md: Remarkable;

  constructor() {
    this.md = new Remarkable('full', {
      breaks: true,
      typographer: true,
      quotes: '“”‘’',
    });

    // Adds Z-B markdown plugins.
    this.md.use(alignRightPlugin);
    this.md.use(underlinePlugin);
    this.md.use(bracketPlugin);
    this.md.use(boxFencePlugin);
  }

  set(config: Remarkable.Options) {
    this.md.set(config);
  }

  use(plugin: Remarkable.Plugin, options?: any) {
    this.md.use(plugin, options);
  }

  parse(value: string): Remarkable.Token[] {
    return this.md.parse(value, {});
  }

  parseInline(value: string): Remarkable.Token[] {
    return this.md.parseInline(value, {});
  }

  /**
   * Renders the string as HTML.
   */
  render(value: string): string {
    return this.md.render(value);
  }

  renderInline(value: string): string {
    return this.md.renderInline(value);
  }

  renderInlineWithoutTypography(value: string): string {
    const md = new Remarkable('full', {
      breaks: true,
      typographer: false,
      quotes: '',
    });

    // Adds Z-B markdown plugins.
    md.use(alignRightPlugin);
    md.use(underlinePlugin);
    md.use(bracketPlugin);
    md.use(boxFencePlugin);

    return md.renderInline(value);
  }
}
