/* globals WistiaVideo */
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { BootstrapService } from '@core/bootstrap.service';
import { WistiaService } from '@core/wistia.service';

@Component({
  selector: 'zbdr-wistia-container',
  templateUrl: './wistia-container.component.html',
  styleUrls: ['./wistia-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [WistiaService],
})
export class WistiaContainerComponent implements AfterViewInit, OnDestroy {
  @Input()
    wistiaCode: string;

  @Input()
    fullScreen = false;

  @Output()
  public videoEnded = new EventEmitter<void>();

  @Output()
  public videoError = new EventEmitter<void>();

  @Output()
  public videoReady = new EventEmitter<void>();

  private video: any;

  constructor(
    private zone: NgZone,
    private appRef: ApplicationRef,
    private bootstrap: BootstrapService,
    private wistia: WistiaService) { }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.initialize();
    });
  }

  private initialize() {
    const endEmiter = this.videoEnded;
    this.wistia.loadVideo(this.wistiaCode)
      .subscribe((video: WistiaVideo) => {
        this.video = video;
        this.video.bind('end', () => {
          this.video.chrome.setAttribute('tabindex', '-1');
          if (this.bootstrap.isLocalUrl) {
            console.log('wistia video ended');
          }
          this.zone.run(() => {
            endEmiter.emit();
            // Forcing a change tracking event, as wistia seems to interfere with change tracking.
            this.appRef.tick();
          });
        });

        this.zone.run(() => {
          this.videoReady.emit();
          this.appRef.tick();
        });
      });
  }

  restart() {
    if (this.video) {
      this.video.time('0m00s');
      this.video.play();
    }
  }

  ngOnDestroy(): void {
    if (this.bootstrap.isLocalUrl) {
      console.log('destroy wistia');
    }
    if (this.video) {
      this.video.remove();
    }
  }
}
