import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zbdr-sk-instructions-panel',
  templateUrl: './sk-instructions-panel.component.html',
  styleUrls: ['./sk-instructions-panel.component.scss']
})
export class SkInstructionsPanelComponent {
  constructor(public modal: NgbActiveModal) { }

  @Input() instructions: string = '';

  dismiss(): void {
    this.modal.dismiss();
  }
}
