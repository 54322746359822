/* globals WistiaVideo */
import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';

import { BootstrapService } from './bootstrap.service';
import { SfxService } from './sfx.service';

@Injectable()
export class WistiaService {
  constructor(private bootstrap: BootstrapService, private sfx: SfxService) { }

  loadVideo(id: string, playerOptions?: any): Observable<WistiaVideo> {
    const subject = new AsyncSubject<WistiaVideo>();
    const wndw: any = window;
    const options = playerOptions || {
      // videoFoam: true,
      autoPlay: false,
      playerColor: '#000000'
    };
    wndw._wq = wndw._wq || [];
    wndw._wq.push({
      id,
      options,
      onHasData: (video: WistiaVideo) => {
        video.chrome.focus();
        video.chrome.setAttribute('tabindex', '0');
        video.bind('play', () => {
          this.sfx.stopQueue();
          if (this.bootstrap.isLocalUrl) {
            console.log(`'play' event fired for ${video.name()}`);
          }
          return video.unbind;
        });
        subject.next(video);
      },
      onReady: () => {
        subject.complete();
      },
    });

    return subject.asObservable();
  }
}
